(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/selectors/teams-selectors.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/selectors/teams-selectors.js');
"use strict";

const {
  FetchState
} = svs.components.marketplaceCompetition.constants;
const defaultState = {
  status: FetchState.Idle,
  data: undefined,
  lastFetched: null
};
const selectTeams = (state, competitionId) => {
  var _state$Competition;
  return ((_state$Competition = state.Competition) === null || _state$Competition === void 0 ? void 0 : _state$Competition.Teams[competitionId]) || defaultState;
};
const selectMembers = (state, competitionId, teamId) => {
  var _teams$teamId;
  const teams = selectTeams(state, competitionId).data || {};
  return ((_teams$teamId = teams[teamId]) === null || _teams$teamId === void 0 || (_teams$teamId = _teams$teamId.data) === null || _teams$teamId === void 0 ? void 0 : _teams$teamId.members) || [];
};
const isCurrentMember = (state, competitionId, teamId) => {
  const members = selectMembers(state, competitionId, teamId);
  return members.find(_ref => {
    let {
      isCurrentMember
    } = _ref;
    return isCurrentMember === true;
  });
};
const selectTeamResult = (state, competitionId, teamId) => {
  var _teams$teamId$data$te, _teams$teamId2;
  const teams = selectTeams(state, competitionId).data || [];
  const teamResult = (_teams$teamId$data$te = (_teams$teamId2 = teams[teamId]) === null || _teams$teamId2 === void 0 || (_teams$teamId2 = _teams$teamId2.data) === null || _teams$teamId2 === void 0 ? void 0 : _teams$teamId2.teamResult) !== null && _teams$teamId$data$te !== void 0 ? _teams$teamId$data$te : defaultState;
  return teamResult;
};
const selectTeamInvitationStatus = state => {
  var _state$Competition2;
  return state === null || state === void 0 || (_state$Competition2 = state.Competition) === null || _state$Competition2 === void 0 || (_state$Competition2 = _state$Competition2.Teams) === null || _state$Competition2 === void 0 || (_state$Competition2 = _state$Competition2.invitation) === null || _state$Competition2 === void 0 ? void 0 : _state$Competition2.status;
};

setGlobal('svs.components.marketplaceCompetition.services.selectors', {
  isCurrentMember,
  selectTeams,
  selectTeamResult,
  selectMembers,
  selectTeamInvitationStatus
});

 })(window);