(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/reducers/teams-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/reducers/teams-reducer.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  createReducer
} = RTK;
const {
  fetchTeams,
  deleteTeam,
  joinTeamByInvitation,
  leaveTeam,
  fetchTeamResult
} = svs.components.marketplaceCompetition.services.actions.teamsActions;
const {
  FetchState
} = svs.components.marketplaceCompetition.constants;
const teamsReducer = createReducer({
  invitation: {
    status: FetchState.Idle
  }
}, builder => {
  builder.addCase(fetchTeams.fulfilled, (state, action) => {
    state[action.meta.arg] = _objectSpread(_objectSpread({}, state[action.meta.arg]), {}, {
      data: action.payload.teams,
      status: FetchState.Fulfilled,
      lastFetched: new Date().toISOString()
    });
  }).addCase(fetchTeams.pending, (state, action) => {
    const competitionId = action.meta.arg;
    state[competitionId] = _objectSpread(_objectSpread({}, state[action.meta.arg]), {}, {
      status: FetchState.Pending
    });
  }).addCase(fetchTeams.rejected, (state, action) => {
    var _state$competitionId;
    const competitionId = action.meta.arg;
    const teamsState = (_state$competitionId = state[competitionId]) === null || _state$competitionId === void 0 ? void 0 : _state$competitionId.data;
    if (teamsState) {
      state[competitionId] = _objectSpread(_objectSpread({}, state[action.meta.arg]), {}, {
        data: [...state[action.meta.arg].data],
        status: FetchState.Rejected,
        lastFetched: new Date().toISOString()
      });
    } else {
      state[competitionId] = {
        data: {},
        status: FetchState.Rejected,
        lastFetched: new Date().toISOString()
      };
    }
  }).addCase(deleteTeam.fulfilled, (state, action) => {
    const {
      competitionId,
      teamId
    } = action.meta.arg;
    const teamsState = state[competitionId].data;
    delete teamsState[teamId];
  }).addCase(leaveTeam.fulfilled, (state, action) => {
    const {
      isCurrentMember,
      competitionId,
      teamId
    } = action.meta.arg;
    const teamsState = state[competitionId].data;
    if (isCurrentMember) {
      delete teamsState[teamId];
    } else {
      const teamToLeave = teamsState[teamId].data;
      const membersArray = teamToLeave.members;
      const memberToDelete = membersArray.findIndex(member => member.customerId === action.meta.arg.customerId);
      membersArray.splice(memberToDelete, 1);
    }
  }).addCase(fetchTeamResult.pending, state => {
    state = _objectSpread(_objectSpread({}, state), {}, {
      status: FetchState.Pending,
      error: undefined
    });
  }).addCase(fetchTeamResult.fulfilled, (state, action) => {
    const teamsResult = action.payload[1];
    const {
      teamId
    } = action.meta.arg;
    const currentTeam = state[action.meta.arg.competitionId].data[teamId];
    if (currentTeam) {
      currentTeam.data.teamResult = {
        data: teamsResult[teamId],
        invitation: FetchState.Idle,
        status: FetchState.Fulfilled,
        lastFetch: new Date().toISOString()
      };
    }
  }).addCase(fetchTeamResult.rejected, (state, action) => {
    state = _objectSpread(_objectSpread({}, state), {}, {
      status: FetchState.Rejected,
      error: action.payload
    });
  }).addCase(joinTeamByInvitation.fulfilled, (state ) => {
    state.invitation.status = FetchState.Fulfilled;
  }).addCase(joinTeamByInvitation.pending, (state ) => {
    state.invitation.status = FetchState.Pending;
  }).addCase(joinTeamByInvitation.rejected, (state ) => {
    state.invitation.status = FetchState.Rejected;
  });
});
setGlobal('svs.components.marketplaceCompetition.services.reducers.teamsReducer', teamsReducer);

 })(window);