(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-competition.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-competition.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;

const {
  useEffect
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  fetchCompetition
} = svs.components.marketplaceCompetition.services.actions.competitionActions;
const {
  selectCompetition
} = svs.components.marketplaceCompetition.services.selectors.competitionSelectors;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const useCompetition = function (competitionId) {
  let skip = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const dispatch = useDispatch();
  const competition = useSelector(state => selectCompetition(state, competitionId));
  const hasCompetitionData = Boolean(competition);
  useEffect(() => {
    if (!hasCompetitionData && competitionId && !isSelfService && !skip) {
      dispatch(fetchCompetition({
        competitionId
      }));
    }
  }, [hasCompetitionData, competitionId, dispatch, skip]);
  return competition;
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.useCompetition', useCompetition);

 })(window);