(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/reducers/highscore-result-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/reducers/highscore-result-reducer.js');
"use strict";

const {
  createReducer,
  combineReducers
} = RTK;
const {
  FetchState
} = svs.components.marketplaceCompetition.constants;
const {
  fetchCompetitionHighscore
} = svs.components.marketplaceCompetition.services.actions.highscoreResultActions;
const PayloadType = {
  Individual: 'Individual',
  Team: 'Team'
};
const resultInitialState = {
  SearchTerm: '',
  Competitions: {}
};
const createHighscoreResultReducer = payloadType => {
  const payloadTypeField = payloadType === PayloadType.Individual ? 'Individuals' : 'Teams';
  return createReducer(resultInitialState, builder => {
    const getResultModel = function (state, competitionId) {
      let reset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      if (!state.Competitions[competitionId] || reset) {
        state.Competitions[competitionId] = {
          data: {
            result: {},
            totalCount: 0
          },
          status: FetchState.Idle
        };
      }
      return state.Competitions[competitionId];
    };
    builder.addCase(fetchCompetitionHighscore.pending, (state, action) => {
      if (action.meta.arg.payloadType === payloadType) {
        const resultModel = getResultModel(state, action.meta.arg.competitionId);
        resultModel.status = FetchState.Pending;
      }
    }).addCase(fetchCompetitionHighscore.fulfilled, (state, action) => {
      if (action.meta.arg.payloadType === payloadType) {
        let clearResults = false;
        if (state.SearchTerm !== action.meta.arg.search) {
          state.SearchTerm = action.meta.arg.search;
          clearResults = true;
        }
        const resultModel = getResultModel(state, action.meta.arg.competitionId, clearResults);
        resultModel.status = FetchState.Fulfilled;
        const competitionIds = Object.keys(action.payload);
        competitionIds.forEach(cId => {
          const cIdNumber = parseInt(cId, 10);
          if (cIdNumber === action.meta.arg.competitionId) {
            if (action.payload[cId][payloadTypeField].totalCount) {
              resultModel.data.totalCount = action.payload[cId][payloadTypeField].totalCount;
            }
            const individualPositions = Object.keys(action.payload[cId][payloadTypeField].result);
            individualPositions.forEach(p => {
              resultModel.data.result[action.payload[cId][payloadTypeField].result[p].uid] = action.payload[cId][payloadTypeField].result[p];
            });
          }
        });
      }
    }).addCase(fetchCompetitionHighscore.rejected, (state, action) => {
      if (action.meta.arg.payloadType === payloadType) {
        const resultModel = getResultModel(state, action.meta.arg.competitionId);
        resultModel.status = FetchState.Rejected;
      }
    });
  });
};
const highscoreResultReducer = combineReducers({
  Teams: createHighscoreResultReducer(PayloadType.Team),
  Individuals: createHighscoreResultReducer(PayloadType.Individual)
});
setGlobal('svs.components.marketplaceCompetition.services.reducers.highscoreResultReducer', highscoreResultReducer);

 })(window);