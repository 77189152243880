(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/services/fetch-player-competition-draws.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/services/fetch-player-competition-draws.js');

const trinidad = svs.isServer ? require('trinidad-core').core : svs.core;
const { jupiter } = trinidad;
const logger = svs.isServer ? trinidad.logger('marketplace-competition:services') : trinidad.log.getLogger('marketplace-competition:services');

const fetchPlayerCompetitionDraws = async (playerCompetitionsIds, req) => {
  try {
    let urls;
    urls = playerCompetitionsIds.map((compId) => {
      return encodeURIComponent(`/player/1/competitions/${compId}/drawstatus`);
    });
    let options;
    if (urls) {
      options = {
        method: 'GET',
        path: '/multifetch?serial=true&urls=' + urls.join('|'),
        req
      };
    }
    const structurePlayerCompetitionsDraws = {};
    const response = await jupiter.callAsync(options);
    const responses = response.responses;
    responses.forEach((value, key) => {
      if (value.hasOwnProperty('error')) {
        throw new Error(`Api ${key} returned an error: ${value.error.message}`);
      }
      structurePlayerCompetitionsDraws[playerCompetitionsIds[responses.indexOf(value)]] = value.participatedDraws;
    });
    return structurePlayerCompetitionsDraws;
  } catch (err) {
    logger.info(`Mkp-competition: Error fetching player competition draws: ${err}`, req);
    throw err;
  }
};
if (svs.isServer) {
  module.exports = fetchPlayerCompetitionDraws;
} else {
  setGlobal('svs.components.marketplaceCompetition.services.services.fetchPlayerCompetitionDraws', fetchPlayerCompetitionDraws);
}


 })(window);