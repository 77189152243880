(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/actions/wagers.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/actions/wagers.js');
"use strict";



const {
  createAsyncThunk
} = RTK;
const {
  jupiter,
  userSession,
  session
} = svs.core;
const {
  FetchState
} = svs.components.marketplaceCompetition.constants;

const {
  normalizeWagers
} = svs.components.marketplaceCompetition.services.helpers.normalizeWagers;
const WAGER_CACHE_TIME = 5000;
const logger = svs.core.log.getLogger('marketplace-competition:wagers');

const fetchWagers = createAsyncThunk('fetchWagers', _fetchWagerPayloadCreator, {
  condition: _fetchWagerConditionCallback
});

async function _fetchWagerPayloadCreator(competitionId) {
  if (session.data.userSession.isAnonymous) {
    logger.info("Mkp-competition: fetchwagers isAnonymous, wagers.es6");
    throw new Error('Can\'t fetchWagers with anonymous session');
  }
  const result = await jupiter.callAsync({
    method: 'GET',
    path: "/player/1/competitions/".concat(competitionId, "/wagers?include=wagers.wagerId,wagers.currentDrawNumber")
  });
  return normalizeWagers(result.response.wagers);
}

function _fetchWagerConditionCallback(competitionId, _ref) {
  let {
    getState
  } = _ref;
  const isPlayer = userSession.hasRole(userSession.roles.PLAYER);
  if (!isPlayer) {
    return false;
  }
  const wagers = getState().Competition.Wagers[competitionId];
  if (wagers) {
    const {
      status,
      lastFetch
    } = wagers;
    const earliestNextFetchTS = new Date(lastFetch).getTime() + WAGER_CACHE_TIME;
    if (status === FetchState.Pending || Date.now() < earliestNextFetchTS) {
      return false;
    }
  }
  return true;
}

setGlobal('svs.components.marketplaceCompetition.services.actions.wagersActions', {
  fetchWagers
});

 })(window);