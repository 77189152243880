(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-ask-for-competition-participation.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-ask-for-competition-participation.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;
const {
  useCallback,
  useMemo
} = React;
const {
  resolveProductId
} = svs.utils.products;
const {
  tipssmParticipateDialog
} = svs.components.sport.tipssmParticipateDialog;
const {
  useCompetition
} = svs.components.marketplaceCompetition.services.hooks;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const {
  getLogger
} = svs.core.log;
const wagerLogger = getLogger('marketplace-competition:competition-wager');
const useExtractCompetitionData = (competition, productId, selectedSystemPrice, selectedSystemType) => {
  const canCompete = useMemo(() => {
    if (isSelfService) {
      return false;
    }
    if (!competition || !competition.details || !selectedSystemType || !selectedSystemPrice || !productId) {
      return false;
    }
    const competitionProductId = resolveProductId(competition.details.productId);
    const competitionPrice = parseInt(competition.details.systemNumber, 10);
    const competitionSystemType = competition.details.systemType.toUpperCase();
    const isCompetitionSystemType = selectedSystemType === competitionSystemType;
    const isCompetitionProductId = productId === competitionProductId;
    const isCompetitionPrice = selectedSystemPrice === competitionPrice;
    return isCompetitionSystemType && isCompetitionProductId && isCompetitionPrice;
  }, [competition, productId, selectedSystemPrice, selectedSystemType]);
  return useMemo(() => ({
    canCompete
  }), [canCompete]);
};
const SUPPORTED_DRAW_COUNT = 1;

const useAskForCompetitionParticipation = _ref => {
  let {
    competitionId,
    selectedSystemType,
    selectedSystemPrice,
    productId,
    drawNumber,
    drawCount
  } = _ref;
  const competition = useCompetition(competitionId);
  const {
    canCompete
  } = useExtractCompetitionData(competition, productId, selectedSystemPrice, selectedSystemType);
  return useCallback(async () => new Promise(resolve => {
    const resolveCompetitionAnswer = function (isPlayingCompetition) {
      let isCompetitionSignUp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      resolve({
        isPlayingCompetition,
        isCompetitionSignUp,
        competition,
        competitionId
      });
    };
    if (canCompete && drawCount === SUPPORTED_DRAW_COUNT) {
      wagerLogger.info('Tips-SM dialog shown');
      tipssmParticipateDialog({
        productId,
        drawNumber,
        competition,
        competitionId,
        onParticipate: (compDesc, isCompetitionSignUp) => {
          let logInfo = '';
          if (isCompetitionSignUp) {
            logInfo = "Joined competition [".concat(competitionId, "] from dialog");
          } else {
            logInfo = "Accepted wager in competition [".concat(competitionId, "]");
          }
          wagerLogger.info(logInfo);
          resolveCompetitionAnswer(true, isCompetitionSignUp);
        },
        onRefuse: userChoice => {
          if (userChoice) {
            wagerLogger.info('Declined competition from dialog');
          }
          resolveCompetitionAnswer(false);
        }
      });
    } else {
      resolveCompetitionAnswer(false);
    }
  }), [canCompete, competition, competitionId, productId, drawNumber, drawCount]);
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.useAskForCompetitionParticipation', useAskForCompetitionParticipation);

 })(window);