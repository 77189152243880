(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-valid-team-ids.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-valid-team-ids.js');
"use strict";

const {
  useTeams
} = svs.components.marketplaceCompetition.services.hooks;
const useValidTeamsIds = competitionId => {
  const {
    teams
  } = useTeams(competitionId);
  if (teams) {
    const validTeams = Object.values(teams).filter(team => team.data.members.length >= 4);
    if (validTeams.length > 0) {
      return validTeams.map(team => team.data.id);
    }
  }
  return [];
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.useValidTeamsIds', useValidTeamsIds);

 })(window);