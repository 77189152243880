(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/middlewares/analytics-mw.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/middlewares/analytics-mw.js');
"use strict";


const CREATE_TEAM = 'createTeam/fulfilled';
function buildTeamCreatedEvent(compType) {
  return {
    category: compType,
    action: 'created team',
    opt_label: 'Skapa lag'
  };
}
function handleAction(action) {
  var _action$meta;
  let event;
  switch (action.type) {
    case CREATE_TEAM:
      event = buildTeamCreatedEvent((_action$meta = action.meta) === null || _action$meta === void 0 || (_action$meta = _action$meta.arg) === null || _action$meta === void 0 ? void 0 : _action$meta.compType);
      break;
    default:
      return;
  }
  svs.components.analytics.trackEvent(event);
}
const analyticsMw = () => next => action => {
  next(action);
  handleAction(action);
};
setGlobal('svs.components.marketplaceCompetition.services.middlewares.analyticsMw', analyticsMw);

 })(window);