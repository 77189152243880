(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-team-result.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-team-result.js');
"use strict";

const {
  useEffect
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  fetchTeamResult
} = svs.components.marketplaceCompetition.services.actions.teamsActions;
const {
  selectTeamResult
} = svs.components.marketplaceCompetition.services.selectors;
const {
  useTeams
} = svs.components.marketplaceCompetition.services.hooks;
const useTeamResult = (competitionId, teamId) => {
  const dispatch = useDispatch();
  const {
    teams: teamsData
  } = useTeams(competitionId);
  const teamResult = useSelector(state => selectTeamResult(state, competitionId, teamId));
  useEffect(() => {
    if (teamsData && teamsData[teamId] && !teamResult.data) {
      dispatch(fetchTeamResult({
        competitionId,
        teamId
      }));
    }
  }, [teamResult, competitionId, teamId, dispatch, teamsData]);
  return teamResult;
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.useTeamResult', useTeamResult);

 })(window);