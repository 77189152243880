(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-competition-mode-for-path.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-competition-mode-for-path.js');
"use strict";


const {
  useEffect
} = React;
const {
  matchPath,
  useLocation
} = ReactRouterDOM;
const {
  useCompetitionDispatcher
} = svs.components.marketplaceCompetition.services.hooks;

const useCompetitionModeForPath = (competitionId, productIds, competitionPaths, competitionStatus) => {
  const location = useLocation();
  const [hasCompetitions, dispatchCompetition, dispatchCompetitionClear] = useCompetitionDispatcher(competitionId, productIds);
  useEffect(() => {
    let cancel;
    const match = matchPath(location.pathname, {
      path: competitionPaths,
      exact: true,
      strict: false
    });
    if (match && competitionStatus) {
      dispatchCompetition();
      cancel = true;
    } else {
      dispatchCompetitionClear();
      cancel = false;
    }
    return () => {
      if (cancel) {
        dispatchCompetitionClear();
      }
    };
  }, [location.pathname, dispatchCompetition, dispatchCompetitionClear, competitionPaths, competitionStatus]);
  return hasCompetitions;
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.useCompetitionModeForPath', useCompetitionModeForPath);

 })(window);