(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-is-competition-in-current-product.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-is-competition-in-current-product.js');
"use strict";


const useIsCompetitionInCurrentProduct = (competitionProductId, drawProductIds) => {
  let isCompetitionInCurrentProduct;
  if (competitionProductId && drawProductIds) {
    isCompetitionInCurrentProduct = drawProductIds.includes(competitionProductId);
  }
  return isCompetitionInCurrentProduct;
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.useIsCompetitionInCurrentProduct', useIsCompetitionInCurrentProduct);

 })(window);