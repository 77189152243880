(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/actions/competition-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/actions/competition-actions.js');
"use strict";



const {
  createAsyncThunk,
  createAction
} = RTK;
const {
  FetchState,
  CacheTime
} = svs.components.marketplaceCompetition.constants;
const {
  competitionHandler
} = svs.components.marketplaceCompetition.competitionHandler;
const fetchCompetitionCondition = (_ref, _ref2) => {
  let {
    competitionId,
    shouldFetchNewCompetition = false
  } = _ref;
  let {
    getState
  } = _ref2;
  if (!shouldFetchNewCompetition) {
    var _state$Competition$Co, _state$Competition$Co2, _state$Competition$Co3;
    const state = getState();
    const lastLoaded = new Date(state === null || state === void 0 || (_state$Competition$Co = state.Competition.Competitions.knownCompetitions[competitionId]) === null || _state$Competition$Co === void 0 ? void 0 : _state$Competition$Co.lastFetch);
    let elapsedMinutes;
    if (dateFns.isValid(lastLoaded)) {
      elapsedMinutes = dateFns.differenceInMinutes(new Date(), lastLoaded);
    } else {
      elapsedMinutes = Number.POSITIVE_INFINITY;
    }
    const isLoading = ((_state$Competition$Co2 = (_state$Competition$Co3 = state.Competition.Competitions.knownCompetitions[competitionId]) === null || _state$Competition$Co3 === void 0 ? void 0 : _state$Competition$Co3.status) !== null && _state$Competition$Co2 !== void 0 ? _state$Competition$Co2 : FetchState.Idle) === FetchState.Pending;
    if (isLoading || elapsedMinutes < CacheTime.Found.minutes) {
      return false;
    }
  }
};

const fetchCompetition = createAsyncThunk('fetchCompetition', async _ref3 => {
  let {
    competitionId
  } = _ref3;
  const comp = await competitionHandler().getCompetition(competitionId);
  return comp.getData();
}, {
  condition: fetchCompetitionCondition
});
const fetchParticipantsForCompetition = createAsyncThunk('fetchParticipantsForCompetition', async _ref4 => {
  let {
    competitionId
  } = _ref4;
  const response = await competitionHandler().getCompetitionParticipants(competitionId);
  return response;
}, {
  condition: (competitionId, _ref5) => {
    var _state$Competition$Co4, _state$Competition$Co5, _state$Competition$Co6, _state$Competition$Co7;
    let {
      getState
    } = _ref5;
    const state = getState();
    const isLoading = ((_state$Competition$Co4 = (_state$Competition$Co5 = state.Competition.Competitions.participants[competitionId]) === null || _state$Competition$Co5 === void 0 ? void 0 : _state$Competition$Co5.status) !== null && _state$Competition$Co4 !== void 0 ? _state$Competition$Co4 : FetchState.Idle) === FetchState.Pending;
    const isFulfilled = ((_state$Competition$Co6 = (_state$Competition$Co7 = state.Competition.Competitions.participants[competitionId]) === null || _state$Competition$Co7 === void 0 ? void 0 : _state$Competition$Co7.status) !== null && _state$Competition$Co6 !== void 0 ? _state$Competition$Co6 : FetchState.Idle) === FetchState.Fulfilled;
    if (isLoading || isFulfilled) {
      return false;
    }
  }
});
const updateCompetition = createAction('tipsen/updateCompetition');
const setCompetitionMode = createAction('tipsen/setCompetitionMode');
const clearCompetitionMode = createAction('tipsen/clearCompetitionMode');
setGlobal('svs.components.marketplaceCompetition.services.actions.competitionActions', {
  fetchCompetition,
  setCompetitionMode,
  clearCompetitionMode,
  updateCompetition,
  fetchParticipantsForCompetition
});

 })(window);