(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-can-play-in-competition-draw.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-can-play-in-competition-draw.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;
const {
  selectPlayerCompetition
} = svs.components.marketplaceCompetition.services.selectors.playerCompetitionSelectors;
const {
  selectCompetition,
  selectIsDrawIncludedInCompetition
} = svs.components.marketplaceCompetition.services.selectors.competitionSelectors;
const {
  useSelector
} = ReactRedux;
const {
  usePlayerCompetitionDraws,
  useActiveCompetitionId
} = svs.components.marketplaceCompetition.services.hooks;
const {
  products
} = svs.utils;
const {
  useMemo
} = React;
const hasPlayer = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;

const useCanPlayInCompetitionDraw = (_ref, competitionId, alwaysDispatch) => {
  var _competition$details2;
  let {
    productId,
    drawNumber
  } = _ref;
  const isDrawIncludedInCompetition = useSelector(state => selectIsDrawIncludedInCompetition(state, productId, drawNumber, competitionId));
  const playerCompetition = useSelector(state => selectPlayerCompetition(state, competitionId));
  const activeCompetitionId = useActiveCompetitionId();
  const competition = useSelector(state => selectCompetition(state, competitionId));
  const playerCompetitionsDraws = usePlayerCompetitionDraws(competitionId, isDrawIncludedInCompetition && (alwaysDispatch || Boolean(activeCompetitionId)));
  return useMemo(() => {
    var _competition$details;
    if (isSelfService) {
      return true;
    }
    const isFirstDrawInCompetition = drawNumber === (competition === null || competition === void 0 || (_competition$details = competition.details) === null || _competition$details === void 0 ? void 0 : _competition$details.firstDrawNumber);
    const hasPlayedOnDraw = playerCompetitionsDraws === null || playerCompetitionsDraws === void 0 ? void 0 : playerCompetitionsDraws.includes(drawNumber);
    return !hasPlayedOnDraw && (isFirstDrawInCompetition || (playerCompetition !== undefined || !hasPlayer) && isDrawIncludedInCompetition);
  }, [competition === null || competition === void 0 || (_competition$details2 = competition.details) === null || _competition$details2 === void 0 ? void 0 : _competition$details2.firstDrawNumber, isDrawIncludedInCompetition, drawNumber, playerCompetition, playerCompetitionsDraws]);
};

const useCanPlayInCompetitionProductDraw = (_ref2, competitionId, alwaysDispatch) => {
  let {
    productId,
    drawNumber
  } = _ref2;
  return useCanPlayInCompetitionDraw({
    productId: products.resolveProductId(productId),
    drawNumber
  }, competitionId, alwaysDispatch);
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.useCanPlayInCompetitionDraw', {
  useCanPlayInCompetitionDraw,
  useCanPlayInCompetitionProductDraw
});

 })(window);