(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/helpers/teams-helper.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/helpers/teams-helper.js');
"use strict";


const {
  CompetitionState
} = svs.components.marketplaceCompetition.constants;
const {
  REG_CLOSED_DRAW_FINALIZED,
  REG_OPEN_DRAW_FINALIZED,
  REG_OPEN_DRAW_DEFINED,
  REG_CLOSED_DRAW_DEFINED,
  REG_OPEN_DRAW_OPEN,
  REG_CLOSED_DRAW_OPEN,
  FINISHED
} = svs.components.marketplaceCompetition.competitionHandler.common.constants.competitionStates;

const getTeamStatus = _ref => {
  var _competition$details, _team$details;
  let {
    team,
    competition
  } = _ref;
  if (!Array.isArray(team === null || team === void 0 ? void 0 : team.members) || !(competition !== null && competition !== void 0 && competition.details)) {
    return '';
  }
  const getIsCurrentDrawDefinedOrOpen = competitionStatus => {
    switch (competitionStatus) {
      case REG_OPEN_DRAW_DEFINED:
      case REG_CLOSED_DRAW_DEFINED:
      case REG_OPEN_DRAW_OPEN:
      case REG_CLOSED_DRAW_OPEN:
        return true;
      default:
        return false;
    }
  };
  const {
    currentDrawNumber,
    firstDrawNumber,
    startTime: signupStopTime,
    state
  } = competition.details;
  const competitionStatus = competition.status;
  const currentDrawNumberToUse = competitionStatus === REG_CLOSED_DRAW_FINALIZED ? currentDrawNumber - 1 : currentDrawNumber;
  const isSignupDone = Date.now() >= new Date(signupStopTime).getTime();
  const isCompetitionDone = state === CompetitionState.CompetitionComplete || competitionStatus === FINISHED;
  const weekNumber = currentDrawNumberToUse - firstDrawNumber + 1;
  const hasEveryonePlayedCurrentRound = team.members.every(member => member.hasPlayedCurrentRound);
  const numberOfCompetitionDraws = competition === null || competition === void 0 || (_competition$details = competition.details) === null || _competition$details === void 0 ? void 0 : _competition$details.numDraws;
  const hasMoreRounds = weekNumber < numberOfCompetitionDraws;
  const isCurrentDrawDefinedOrOpen = getIsCurrentDrawDefinedOrOpen(competitionStatus);
  const isCurrentDrawFinalized = competitionStatus === REG_OPEN_DRAW_FINALIZED || competitionStatus === REG_CLOSED_DRAW_FINALIZED;
  const currentTeamDrawNumber = team === null || team === void 0 || (_team$details = team.details) === null || _team$details === void 0 ? void 0 : _team$details.currentDraw;

  const isDirty = currentTeamDrawNumber && currentTeamDrawNumber !== currentDrawNumberToUse;
  if (!team.complete) {
    return isSignupDone ? 'Deltar inte i tävlingen' : 'För få medlemmar';
  }
  if (isDirty || isCompetitionDone || isCurrentDrawFinalized && !hasMoreRounds) {
    return '';
  }
  if (isCurrentDrawDefinedOrOpen) {
    return hasEveryonePlayedCurrentRound ? "Alla spel f\xF6r omg\xE5ng ".concat(weekNumber, " lagda") : "Inv\xE4ntar spel f\xF6r omg\xE5ng ".concat(weekNumber);
  }
  return isCurrentDrawFinalized ? "Inv\xE4ntar spel f\xF6r omg\xE5ng ".concat(weekNumber + 1) : "Inv\xE4ntar resultat f\xF6r omg\xE5ng ".concat(weekNumber);
};
setGlobal('svs.components.marketplaceCompetition.services.helpers.teamHelper', {
  getTeamStatus
});

 })(window);