(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-teams-ids.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-teams-ids.js');
"use strict";

const {
  useTeams
} = svs.components.marketplaceCompetition.services.hooks;
const useTeamsIds = competitionId => {
  const {
    teams
  } = useTeams(competitionId);
  if (teams) {
    const teamsIds = Object.keys(teams);
    teamsIds.forEach(teamId => parseInt(teamId, 10));
    return teamsIds.map(teamId => parseInt(teamId, 10));
  }
  return undefined;
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.useTeamsIds', useTeamsIds);

 })(window);