(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-active-competition-details.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-active-competition-details.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  useActiveCompetitionId
} = svs.components.marketplaceCompetition.services.hooks;
const {
  selectCompetitionDetails
} = svs.components.marketplaceCompetition.services.selectors.competitionSelectors;
const useActiveCompetitionDetails = () => {
  const activeCompetitionId = useActiveCompetitionId();
  return useSelector(state => selectCompetitionDetails(state, activeCompetitionId));
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.useActiveCompetitionDetails', useActiveCompetitionDetails);

 })(window);