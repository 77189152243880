(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/actions/create-team-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/actions/create-team-actions.js');
"use strict";


const {
  createAction,
  createAsyncThunk
} = RTK;
const {
  jupiter
} = svs.core;
const createTeam = createAsyncThunk('createTeam', _ref => {
  let {
    competitionId,
    teamName,
    callback
  } = _ref;
  return new Promise((resolve, reject) => {
    if (svs.core.session.data.userSession.isAnonymous) {
      reject(new Error('Cant create competition teams with anonymous session'));
    }
    jupiter.put({
      path: "/competition/2/competitions/".concat(competitionId, "/teams"),
      data: {
        comment: '',
        teamId: 0,
        teamOwner: 0,
        retailerId: 0,
        name: teamName
      }
    }, data => {
      callback(null, data);
      resolve(data); 
    }, error => {
      callback(error.responseJSON.error);
      reject(error.responseJSON.error);
    });
  });
});
const setCreateTeam = createAction('setCreateTeam');
setGlobal('svs.components.marketplaceCompetition.services.actions.createTeamActions', {
  createTeam,
  setCreateTeam
});

 })(window);