(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-personal-results.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-personal-results.js');
"use strict";

const {
  useEffect
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  fetchPersonalResultsAction
} = svs.components.marketplaceCompetition.services.actions.personalResultActions;
const {
  selectPersonalResults
} = svs.components.marketplaceCompetition.services.selectors.personalResultSelectors;
const usePersonalResults = function (competitionId, teamIds) {
  let shouldFetchIndividualResults = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  const dispatch = useDispatch();
  const competitionResult = useSelector(state => selectPersonalResults(state, competitionId));
  useEffect(() => {
    if (competitionId && teamIds && !competitionResult) {
      dispatch(fetchPersonalResultsAction({
        competitionId,
        teamIds,
        shouldFetchIndividualResults
      }));
    }
  }, [competitionResult, competitionId, teamIds, dispatch, shouldFetchIndividualResults]);
  return competitionResult;
};
usePersonalResults.propTypes = {
  competitionId: PropTypes.number,
  shouldFetchIndividualResults: PropTypes.bool,
  teamIds: PropTypes.array
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.usePersonalResults', usePersonalResults);

 })(window);