(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/selectors/player-competition-selectors.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/selectors/player-competition-selectors.js');
"use strict";

const {
  FetchState
} = svs.components.marketplaceCompetition.constants;
const competitionCacheTime = 5000;
const selectPlayerCompetitionsStatus = state => [state.Competition.PlayerCompetitions.signUps.status, state.Competition.PlayerCompetitions.signUps.lastFetch];
const selectPlayerCompetitions = state => {
  var _state$Competition;
  return (_state$Competition = state.Competition) === null || _state$Competition === void 0 ? void 0 : _state$Competition.PlayerCompetitions.signUps.competitions;
};
const selectPlayerCompetition = (state, competitionId) => {
  var _selectPlayerCompetit;
  return (_selectPlayerCompetit = selectPlayerCompetitions(state)) === null || _selectPlayerCompetit === void 0 ? void 0 : _selectPlayerCompetit[competitionId];
};
const selectPlayerCompetitionDraws = (state, competitionId) => {
  var _state$Competition2, _state$Competition3;
  if ((_state$Competition2 = state.Competition) !== null && _state$Competition2 !== void 0 && _state$Competition2.PlayerCompetitions.draws && (_state$Competition3 = state.Competition) !== null && _state$Competition3 !== void 0 && _state$Competition3.PlayerCompetitions.draws[competitionId]) {
    return state.Competition.PlayerCompetitions.draws[competitionId];
  }
  return {};
};
const selectPlayerCompetitionDrawsStatus = (state, competitionId) => {
  var _selectPlayerCompetit2, _selectPlayerCompetit3;
  return (_selectPlayerCompetit2 = (_selectPlayerCompetit3 = selectPlayerCompetitionDraws(state, competitionId)) === null || _selectPlayerCompetit3 === void 0 || (_selectPlayerCompetit3 = _selectPlayerCompetit3.data) === null || _selectPlayerCompetit3 === void 0 ? void 0 : _selectPlayerCompetit3.draws) !== null && _selectPlayerCompetit2 !== void 0 ? _selectPlayerCompetit2 : [];
};
const selectPlayerCompetitionDrawsState = (state, competitionId) => {
  var _selectPlayerCompetit4, _selectPlayerCompetit5;
  return (_selectPlayerCompetit4 = (_selectPlayerCompetit5 = selectPlayerCompetitionDraws(state, competitionId)) === null || _selectPlayerCompetit5 === void 0 ? void 0 : _selectPlayerCompetit5.status) !== null && _selectPlayerCompetit4 !== void 0 ? _selectPlayerCompetit4 : FetchState.Idle;
};
const selectLastFetchedCompetitionDraws = (state, competitionId) => {
  const {
    lastFetch
  } = selectPlayerCompetitionDraws(state, competitionId);
  return !lastFetch ? new Date(null) : new Date(lastFetch);
};
const selectCompetitionDrawsIsToOld = (state, competitionId) => {
  const drawState = selectPlayerCompetitionDrawsState(state, competitionId);
  const lastFetched = selectLastFetchedCompetitionDraws(state, competitionId);
  const currentTime = new Date();
  return drawState !== FetchState.Pending && new Date(lastFetched).getTime() + competitionCacheTime < currentTime.getTime();
};
const selectHasPlayerPlayedCompetitionDraw = (state, competitionId, drawId) => {
  const drawStatus = selectPlayerCompetitionDrawsStatus(state, competitionId);
  return drawStatus && drawStatus.includes(drawId);
};
setGlobal('svs.components.marketplaceCompetition.services.selectors.playerCompetitionSelectors', {
  selectPlayerCompetitionsStatus,
  selectPlayerCompetition,
  selectPlayerCompetitions,
  selectPlayerCompetitionDrawsStatus,
  selectPlayerCompetitionDrawsState,
  selectCompetitionDrawsIsToOld,
  selectHasPlayerPlayedCompetitionDraw
});

 })(window);