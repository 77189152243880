(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/constants/highscore-constants.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/constants/highscore-constants.js');
"use strict";

const PositionDirection = {
  Unmoved: 'Unmoved',
  Up: 'Up',
  Down: 'Down'
};
const HighscoreListType = {
  Individual: 'Individual',
  Team: 'Team'
};
setGlobal('svs.components.marketplaceCompetition.services.constants.highscoreConstants', {
  PositionDirection,
  HighscoreListType
});

 })(window);