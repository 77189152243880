(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/reducers/personal-result-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/reducers/personal-result-reducer.js');
"use strict";

const {
  createReducer
} = RTK;
const {
  FetchState
} = svs.components.marketplaceCompetition.constants;
const {
  fetchPersonalResultsAction
} = svs.components.marketplaceCompetition.services.actions.personalResultActions;
const personalResultState = {
  Competitions: {}
};
const getPersonalResultModel = (state, competitionId) => {
  if (!state.Competitions[competitionId]) {
    state.Competitions[competitionId] = {
      individual: undefined,
      teams: {}
    };
  }
  return state.Competitions[competitionId];
};
const getIndividualModel = (state, competitionId) => {
  const personalResults = getPersonalResultModel(state, competitionId);
  if (!personalResults.individual) {
    personalResults.individual = {
      data: {},
      lastFetch: null,
      status: FetchState.Idle
    };
  }
  return personalResults.individual;
};
const getTeamModel = (state, competitionId, teamId) => {
  const personalResults = getPersonalResultModel(state, competitionId);
  if (!personalResults.teams[teamId]) {
    personalResults.teams[teamId] = {
      data: {},
      lastFetch: null,
      status: FetchState.Idle
    };
  }
  return personalResults.teams[teamId];
};
const personalResultReducer = createReducer(personalResultState, builder => {
  builder.addCase(fetchPersonalResultsAction.pending, (state, action) => {
    const {
      competitionId,
      teamIds,
      shouldFetchIndividualResults
    } = action.meta.arg;
    if (shouldFetchIndividualResults) {
      const individualResult = getIndividualModel(state, competitionId);
      individualResult.status = FetchState.Pending;
    }
    teamIds.forEach(teamId => {
      const teamResult = getTeamModel(state, competitionId, teamId);
      teamResult.status = FetchState.Pending;
    });
  }).addCase(fetchPersonalResultsAction.fulfilled, (state, action) => {
    const newDateString = new Date().toISOString();
    const [individualResult, teamsResult] = action.payload;
    const {
      competitionId,
      teamIds,
      shouldFetchIndividualResults
    } = action.meta.arg;
    if (shouldFetchIndividualResults) {
      const individualResultModel = getIndividualModel(state, competitionId);
      individualResultModel.status = FetchState.Fulfilled;
      individualResultModel.lastFetch = newDateString;
      if (Object.keys(individualResult).length > 0) {
        individualResultModel.data = individualResult;
      }
    }
    teamIds.forEach(teamId => {
      if (Object.keys(teamsResult[teamId]).length > 0) {
        const teamResultModel = getTeamModel(state, competitionId, teamId);
        teamResultModel.status = FetchState.Fulfilled;
        teamResultModel.lastFetch = newDateString;
        teamResultModel.data = teamsResult[teamId];
      } else {
        delete state.Competitions[competitionId].teams[teamId];
      }
    });
  }).addCase(fetchPersonalResultsAction.rejected, (state, action) => {
    const {
      competitionId,
      teamIds,
      shouldFetchIndividualResults
    } = action.meta.arg;
    if (shouldFetchIndividualResults) {
      const individualResult = getIndividualModel(state, competitionId);
      individualResult.status = FetchState.Rejected;
    }
    teamIds.forEach(teamId => {
      const teamResult = getTeamModel(state, competitionId, teamId);
      teamResult.status = FetchState.Rejected;
    });
  });
});
setGlobal('svs.components.marketplaceCompetition.services.reducers.personalResultReducer', personalResultReducer);

 })(window);