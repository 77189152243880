(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/selectors/competition-selectors.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/selectors/competition-selectors.js');
"use strict";


const {
  CompetitionState
} = svs.components.marketplaceCompetition.constants;
const {
  FetchState
} = svs.components.marketplaceCompetition.constants;
const selectCompetition = (state, competitionId) => {
  var _state$Competition$Co, _state$Competition;
  return (_state$Competition$Co = (_state$Competition = state.Competition) === null || _state$Competition === void 0 || (_state$Competition = _state$Competition.Competitions) === null || _state$Competition === void 0 || (_state$Competition = _state$Competition.knownCompetitions) === null || _state$Competition === void 0 || (_state$Competition = _state$Competition[competitionId]) === null || _state$Competition === void 0 ? void 0 : _state$Competition.data) !== null && _state$Competition$Co !== void 0 ? _state$Competition$Co : null;
};
const selectCompetitionDetails = (state, competitionId) => {
  const competition = selectCompetition(state, competitionId);
  return competition ? competition.details : undefined;
};
const selectCompetitionDraws = (state, competitionId) => {
  const details = selectCompetitionDetails(state, competitionId);
  return details === null || details === void 0 ? void 0 : details.drawNumberList;
};
const selectCompetitionStatus = (state, competitionId) => {
  var _state$Competition$Co2, _state$Competition2;
  return (_state$Competition$Co2 = (_state$Competition2 = state.Competition) === null || _state$Competition2 === void 0 || (_state$Competition2 = _state$Competition2.Competitions) === null || _state$Competition2 === void 0 || (_state$Competition2 = _state$Competition2.knownCompetitions) === null || _state$Competition2 === void 0 || (_state$Competition2 = _state$Competition2[competitionId]) === null || _state$Competition2 === void 0 ? void 0 : _state$Competition2.status) !== null && _state$Competition$Co2 !== void 0 ? _state$Competition$Co2 : null;
};
const selectCompetitionsByProductDraw = (state, productId, drawNumber) => {
  var _state$Competition3;
  const productCompetitions = [];
  if ((_state$Competition3 = state.Competition) !== null && _state$Competition3 !== void 0 && _state$Competition3.Competitions.knownCompetitions) {
    for (const [, competition] of Object.entries(state.Competition.Competitions.knownCompetitions)) {
      if (competition.data.details && competition.status === FetchState.Fulfilled && competition.data.details.productId === productId && competition.data.details.drawNumberList.includes(drawNumber)) {
        productCompetitions.push(competition.data);
      }
    }
  }
  return productCompetitions;
};
const selectMaxDrawCount = (state, competitionId) => {
  const details = selectCompetitionDetails(state, competitionId);
  return details ? details.numDraws : 0;
};
const selectCurrentDrawCount = (state, competitionId) => {
  const details = selectCompetitionDetails(state, competitionId);
  return details ? details.currentDrawNumber - details.firstDrawNumber + 1 : 0;
};
const selectDrawCountForDrawNumber = (state, competitionId, drawNumber) => {
  const details = selectCompetitionDetails(state, competitionId);
  return ((details === null || details === void 0 ? void 0 : details.drawNumberList) || []).indexOf(drawNumber) + 1;
};
const selectDrawCountIndexForDrawNumber = (state, competitionId, drawNumber) => {
  const details = selectCompetitionDetails(state, competitionId);
  return ((details === null || details === void 0 ? void 0 : details.drawNumberList) || []).indexOf(drawNumber);
};
const selectCompetitionState = (state, competitionId) => {
  const details = selectCompetitionDetails(state, competitionId);
  return details ? details.state : CompetitionState.Undefined;
};
const selectPlayerCompetition = state => state.Competition.PlayerCompetitions;

const selectPlayerCompetitionById = (state, competitionId) => {
  return state.Competition.PlayerCompetitions.signUps.competitions[competitionId];
};
const selectPlayerCompetitionDraws = state => state.Competition.PlayerCompetitions.draws;
const selectIsDrawIncludedInCompetitionWithId = (state, productId, drawNumber, competitionId) => {
  const drawsList = selectCompetitionsByProductDraw(state, productId, drawNumber).filter(comp => comp.id === competitionId);
  return drawsList.length > 0;
};
const selectIsDrawIncludedInCompetition = (state, productId, drawNumber) => {
  const drawsList = selectCompetitionsByProductDraw(state, productId, drawNumber);
  return drawsList.length > 0;
};
const selectActiveCompetitionId = state => {
  var _state$Competition$Co3, _state$Competition4;
  return (_state$Competition$Co3 = state === null || state === void 0 || (_state$Competition4 = state.Competition) === null || _state$Competition4 === void 0 || (_state$Competition4 = _state$Competition4.Competitions) === null || _state$Competition4 === void 0 ? void 0 : _state$Competition4.activeCompetitionId) !== null && _state$Competition$Co3 !== void 0 ? _state$Competition$Co3 : undefined;
};
setGlobal('svs.components.marketplaceCompetition.services.selectors.competitionSelectors', {
  selectCompetition,
  selectCompetitionDetails,
  selectCompetitionDraws,
  selectCompetitionStatus,
  selectCompetitionsByProductDraw,
  selectMaxDrawCount,
  selectCurrentDrawCount,
  selectDrawCountForDrawNumber,
  selectDrawCountIndexForDrawNumber,
  selectCompetitionState,
  selectPlayerCompetition,
  selectPlayerCompetitionById,
  selectPlayerCompetitionDraws,
  selectIsDrawIncludedInCompetition,
  selectIsDrawIncludedInCompetitionWithId,
  selectActiveCompetitionId
});

 })(window);