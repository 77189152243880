(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-get-competition-result-count.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-get-competition-result-count.js');
"use strict";

const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  useEffect
} = React;
const {
  selectCompetitionResultCount
} = svs.components.marketplaceCompetition.services.selectors.competitionResultSelectors;
const {
  fetchParticipantsForCompetition
} = svs.components.marketplaceCompetition.services.actions.competitionActions;
const useGetCompetitionResultCount = _ref => {
  let {
    competitionId,
    type
  } = _ref;
  const dispatch = useDispatch();
  const totalCount = useSelector(state => selectCompetitionResultCount(state, competitionId, type));
  useEffect(() => {
    if (competitionId && !totalCount && totalCount === null) {
      dispatch(fetchParticipantsForCompetition({
        competitionId
      }));
    }
  }, [competitionId, totalCount, dispatch]);
  return totalCount;
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.useGetCompetitionResultCount', useGetCompetitionResultCount);

 })(window);