(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/reducers/player-competition-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/reducers/player-competition-reducer.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  createReducer
} = RTK;
const {
  putParticipation
} = svs.components.marketplaceCompetition.services.actions.playerCompetitionActions;
const {
  fetchPlayerCompetitions,
  fetchPlayerCompetitionDrawStatus,
  setPlayerCompetitionDraw,
  deletePlayerCompetitionDraw,
  setPlayerCompetitionSignedUp
} = svs.components.marketplaceCompetition.services.actions.playerCompetitionActions;
const {
  FetchState
} = svs.components.marketplaceCompetition.constants;
const playerCompetitionInitialState = {
  signUps: {
    competitions: {},
    status: FetchState.Idle,
    lastFetch: null
  },
  draws: {}
};
const getCompetitionDrawStatusModel = (state, competitionId) => {
  if (!state.draws[competitionId]) {
    state.draws[competitionId] = {
      data: {
        draws: []
      },
      status: FetchState.Idle,
      lastFetch: null
    };
  }
  return state.draws[competitionId];
};
const getCompetitionSignedUpModel = (state, competitionId) => {
  if (!state.signUps.competitions[competitionId]) {
    state.signUps.competitions[competitionId] = {
      competitionId
    };
  }
  return state.signUps.competitions[competitionId];
};
const makePlayerCompetitionReducer = function () {
  let preloadedState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : playerCompetitionInitialState;
  return createReducer(preloadedState, builder => {
    builder.addCase(fetchPlayerCompetitions.fulfilled, (state, action) => {
      state.signUps.competitions = _objectSpread(_objectSpread({}, state.signUps.competitions), action.payload);
      state.signUps.status = FetchState.Fulfilled;
      state.signUps.lastFetch = new Date().toISOString();
      const {
        competitionId
      } = action.meta.arg;
      const competition = state.signUps.competitions[competitionId];
      if (competitionId && !competition) {
        state.signUps.competitions[competitionId] = {
          competitionId,
          unknownCompetition: true
        };
      }
    }).addCase(fetchPlayerCompetitions.pending, state => {
      state.signUps.status = FetchState.Pending;
    }).addCase(fetchPlayerCompetitions.rejected, state => {
      state.signUps.status = FetchState.Rejected;
    }).addCase(fetchPlayerCompetitionDrawStatus.pending, (state, action) => {
      const competitionDrawStatus = getCompetitionDrawStatusModel(state, action.meta.arg);
      competitionDrawStatus.status = FetchState.Pending;
    }).addCase(fetchPlayerCompetitionDrawStatus.fulfilled, (state, action) => {
      const competitionDrawStatus = getCompetitionDrawStatusModel(state, action.meta.arg);
      competitionDrawStatus.status = FetchState.Fulfilled;
      competitionDrawStatus.lastFetch = new Date().toISOString();
      competitionDrawStatus.data = {
        draws: action.payload[action.meta.arg]
      };
    }).addCase(fetchPlayerCompetitionDrawStatus.rejected, (state, action) => {
      const competitionDrawStatus = getCompetitionDrawStatusModel(state, action.meta.arg);
      competitionDrawStatus.status = FetchState.Rejected;
    }).addCase(putParticipation.fulfilled, (state, action) => {
      const {
        competitionId
      } = action.meta.arg;
      const newState = _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        data: _objectSpread({}, action.payload),
        signUps: _objectSpread(_objectSpread({}, state.signUps), {}, {
          competitions: _objectSpread(_objectSpread({}, state.signUps.competitions), {}, {
            [competitionId]: {
              isDirty: true,
              hasJustRegistered: true
            }
          })
        })
      });
      return newState;
    }).addCase(putParticipation.pending, state => _objectSpread(_objectSpread({}, state), {}, {
      isLoading: true
    })).addCase(putParticipation.rejected, (state, action) => _objectSpread(_objectSpread({}, state), {}, {
      isLoading: false,
      error: action.payload
    })).addCase(setPlayerCompetitionDraw, (state, action) => {
      const {
        drawNumber,
        competitionId
      } = action.payload;
      const compData = getCompetitionSignedUpModel(state, competitionId);
      if (!compData.signupTime) {
        compData.isDirty = true;
      }
      const drawModel = getCompetitionDrawStatusModel(state, competitionId);
      drawModel.data.draws.push(drawNumber);
      drawModel.isDirty = true;
    }).addCase(deletePlayerCompetitionDraw, (state, action) => {
      const {
        drawNumber,
        competitionId
      } = action.payload;
      const drawModel = getCompetitionDrawStatusModel(state, competitionId);
      const indexOfDrawNumber = drawModel.data.draws.indexOf(drawNumber);
      drawModel.data.draws.splice(indexOfDrawNumber, 1);
    }).addCase(setPlayerCompetitionSignedUp, (state, action) => {
      const {
        competitionId
      } = action.payload;
      const compData = getCompetitionSignedUpModel(state, competitionId);
      if (!compData.signupTime) {
        compData.isDirty = true;
      }
    });
  });
};
setGlobal('svs.components.marketplaceCompetition.services.reducers.makePlayerCompetitionReducer', makePlayerCompetitionReducer);

 })(window);