(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/services/put-participation.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/services/put-participation.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const trinidad = svs.isServer ? require('trinidad-core').core : svs.core;
const {
  jupiter
} = trinidad;
const {
  SignupType
} = svs.isServer ? trinidad.components.require('marketplace-competition', 'constants').api : svs.components.marketplaceCompetition.constants;
const logger = svs.isServer ? trinidad.logger('marketplace-competition:services') : trinidad.log.getLogger('marketplace-competition:services');
const onEvents = svs.isServer ? {
  publishEvent: () => {},
  eventNames: {}
} : svs.components.lbUtils.onEvents;
const {
  publishEvent,
  eventNames
} = onEvents;
const putParticipation = function (competitionId) {
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let req = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  return new Promise((resolve, reject) => {
    jupiter.put({
      path: "/competition/2/competitions/".concat(competitionId, "/participations"),
      data: _objectSpread({
        signupType: SignupType.PARTICIPATE,
        locationId: 0
      }, data)
    }, data => {
      publishEvent(eventNames.COMPETITION_SIGN_UP, {
        detail: {
          competitionId,
          data
        }
      });
      const response = {
        competitionId,
        data
      };
      resolve(response);
    }, error => {
      logger.info("Mkp-competition: Error put participation: ".concat(error), req);
      reject(error);
    });
  });
};
if (svs.isServer) {
  module.exports = putParticipation;
} else {
  setGlobal('svs.components.marketplaceCompetition.services.services.putParticipation', putParticipation);
}

 })(window);