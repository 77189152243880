(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-competition-type.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-competition-type.js');
"use strict";


const {
  useCompetition
} = svs.components.marketplaceCompetition.services.hooks;
const useCompetitionType = competitionId => {
  const competition = useCompetition(competitionId);
  return competition === null || competition === void 0 ? void 0 : competition.compType;
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.useCompetitionType', useCompetitionType);

 })(window);