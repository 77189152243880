(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/actions/personal-result-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/actions/personal-result-actions.js');
"use strict";

const {
  createAsyncThunk
} = RTK;
const {
  normalizePersonalResults
} = svs.components.marketplaceCompetition.services.helpers;
const {
  selectIndividualResults,
  selectTeamResults
} = svs.components.marketplaceCompetition.services.selectors.personalResultSelectors;
const cacheTime = 10000;
const {
  competitionHandler
} = svs.components.marketplaceCompetition.competitionHandler;
const {
  userSession
} = svs.core;
const fetchPersonalResultsActionCondition = _ref => {
  let {
    competitionId,
    teamIds
  } = _ref;
  if (!competitionId) {
    return false;
  }
  if (userSession.isAnonymous()) {
    return false;
  }
  if (!teamIds) {
    return false;
  }
};
const fetchPersonalResultsAction = createAsyncThunk('competition/fetchPersonalResults', async (_ref2, _ref3) => {
  let {
    competitionId,
    teamIds,
    shouldFetchIndividualResults
  } = _ref2;
  let {
    getState
  } = _ref3;
  const state = getState();
  const nowDate = Date.now();
  const results = [undefined, {}];
  if (shouldFetchIndividualResults) {
    const previousIndividualResults = selectIndividualResults(state, competitionId);
    results[0] = (previousIndividualResults === null || previousIndividualResults === void 0 ? void 0 : previousIndividualResults.data) || {};
    shouldFetchIndividualResults = !previousIndividualResults || new Date(previousIndividualResults.lastFetch).getTime() + cacheTime < nowDate;
  }
  const fetchTeamIds = [];
  teamIds.forEach(teamId => {
    const previousTeamResults = selectTeamResults(state, competitionId, teamId);
    results[1][teamId] = (previousTeamResults === null || previousTeamResults === void 0 ? void 0 : previousTeamResults.data) || {};
    if (!previousTeamResults || new Date(previousTeamResults.lastFetch).getTime() + cacheTime < nowDate) {
      fetchTeamIds.push(teamId);
    }
  });
  if (shouldFetchIndividualResults || fetchTeamIds.length > 0) {
    const response = await competitionHandler().fetchPlayerCompetitionResults(competitionId, fetchTeamIds, shouldFetchIndividualResults);
    const [individualResult, teamsResult] = normalizePersonalResults(response);
    if (individualResult) {
      results[0] = individualResult;
    }
    const teamResultIds = Object.keys(teamsResult);
    teamResultIds.forEach(result => {
      results[1][teamsResult[result].id] = teamsResult[result];
    });
  }
  return results;
}, {
  condition: fetchPersonalResultsActionCondition
});
setGlobal('svs.components.marketplaceCompetition.services.actions.personalResultActions', {
  fetchPersonalResultsAction
});

 })(window);