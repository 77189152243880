(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/reducers/competition-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/reducers/competition-reducer.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  createReducer,
  isAnyOf
} = RTK;
const {
  fetchCompetition,
  setCompetitionMode,
  clearCompetitionMode,
  updateCompetition,
  fetchParticipantsForCompetition
} = svs.components.marketplaceCompetition.services.actions.competitionActions;
const {
  FetchState
} = svs.components.marketplaceCompetition.constants;
const competitionReducerInitState = {
  knownCompetitions: {},
  activeCompetitionId: undefined,
  participants: {}
};
const getCompetitionModel = (state, competitionId) => {
  if (!state.knownCompetitions[competitionId]) {
    state.knownCompetitions[competitionId] = {
      data: {},
      status: FetchState.Idle,
      lastFetch: null
    };
  }
  return state.knownCompetitions[competitionId];
};
const getCompetitionParticipantsModel = (state, competitionId) => {
  if (!state.participants[competitionId]) {
    state.participants[competitionId] = {
      data: {},
      status: FetchState.Idle
    };
  }
  return state.participants[competitionId];
};
const makeCompetitionReducer = function () {
  let preloadedState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : competitionReducerInitState;
  return createReducer(preloadedState, builder => {
    builder.addCase(fetchCompetition.pending, (state, action) => {
      const competition = getCompetitionModel(state, action.meta.arg.competitionId);
      competition.status = FetchState.Pending;
    }).addCase(fetchCompetition.rejected, (state, action) => {
      const competition = getCompetitionModel(state, action.meta.arg.competitionId);
      competition.status = FetchState.Rejected;
    }).addCase(fetchParticipantsForCompetition.pending, (state, action) => {
      const competitionParticipants = getCompetitionParticipantsModel(state, action.meta.arg.competitionId);
      competitionParticipants.status = FetchState.Pending;
    }).addCase(fetchParticipantsForCompetition.rejected, (state, action) => {
      const competitionParticipants = getCompetitionParticipantsModel(state, action.meta.arg.competitionId);
      competitionParticipants.status = FetchState.Rejected;
    }).addCase(fetchParticipantsForCompetition.fulfilled, (state, action) => {
      const competitionParticipants = getCompetitionParticipantsModel(state, action.meta.arg.competitionId);
      competitionParticipants.status = FetchState.Fulfilled;
      competitionParticipants.data = {
        numberOfTeams: action.payload.numberOfTeams,
        numberOfPlayers: action.payload.numberOfPlayers
      };
    }).addCase(setCompetitionMode, (state, action) => {
      state.activeCompetitionId = action.payload.competitionId;
    }).addCase(clearCompetitionMode, state => {
      state.activeCompetitionId = undefined;
    }).addMatcher(isAnyOf(fetchCompetition.fulfilled, updateCompetition), (state, action) => {
      const competition = getCompetitionModel(state, action.payload.id);
      competition.status = FetchState.Fulfilled;
      competition.lastFetch = new Date().toISOString();
      competition.data = _objectSpread({}, action.payload);
    });
  });
};
setGlobal('svs.components.marketplaceCompetition.services.reducers.makeCompetitionReducer', makeCompetitionReducer);

 })(window);