(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/selectors/wagers.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/selectors/wagers.js');
"use strict";


const selectWagers = (state, competitionId) => {
  var _state$Competition$Wa;
  return (_state$Competition$Wa = state.Competition.Wagers[competitionId]) === null || _state$Competition$Wa === void 0 ? void 0 : _state$Competition$Wa.data;
};

setGlobal('svs.components.marketplaceCompetition.services.selectors.wagersSelectors', {
  selectWagers
});

 })(window);