(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-wagers.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-wagers.js');
"use strict";


const {
  useEffect
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  actions: {
    wagersActions: {
      fetchWagers
    }
  },
  selectors: {
    wagersSelectors: {
      selectWagers
    }
  }
} = svs.components.marketplaceCompetition.services;

const useWagers = competitionId => {
  const dispatch = useDispatch();
  const wagers = useSelector(state => selectWagers(state, competitionId));
  useEffect(() => {
    if (!wagers && competitionId) {
      dispatch(fetchWagers(competitionId));
    }
  }, [wagers, competitionId, dispatch]);
  return wagers;
};

setGlobal('svs.components.marketplaceCompetition.services.hooks.useWagers', useWagers);

 })(window);