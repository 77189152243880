(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-is-player-in-competition.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-is-player-in-competition.js');
"use strict";

const {
  selectPlayerCompetition
} = svs.components.marketplaceCompetition.services.selectors.playerCompetitionSelectors;
const {
  selectCompetition,
  selectIsDrawIncludedInCompetitionWithId
} = svs.components.marketplaceCompetition.services.selectors.competitionSelectors;
const {
  useSelector
} = ReactRedux;
const {
  useMemo
} = React;
const hasPlayer = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);

const useIsPlayerInCompetition = (productId, drawNumber, competitionId) => {
  var _competition$details2;
  const playerCompetition = useSelector(state => selectPlayerCompetition(state, competitionId));
  const competition = useSelector(state => selectCompetition(state, competitionId));
  const isDrawIncludedInCompetition = useSelector(state => selectIsDrawIncludedInCompetitionWithId(state, productId, drawNumber, competitionId));
  return useMemo(() => {
    var _competition$details;
    const isFirstDrawInCompetition = drawNumber === (competition === null || competition === void 0 || (_competition$details = competition.details) === null || _competition$details === void 0 ? void 0 : _competition$details.firstDrawNumber);
    return isDrawIncludedInCompetition && (isFirstDrawInCompetition || playerCompetition !== undefined || !hasPlayer);
  }, [competition === null || competition === void 0 || (_competition$details2 = competition.details) === null || _competition$details2 === void 0 ? void 0 : _competition$details2.firstDrawNumber, isDrawIncludedInCompetition, drawNumber, playerCompetition]);
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.useIsPlayerInCompetition', {
  useIsPlayerInCompetition
});

 })(window);