(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-competition-dispatcher.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-competition-dispatcher.js');
"use strict";


const {
  useCompetition
} = svs.components.marketplaceCompetition.services.hooks;
const {
  useCallback,
  useMemo
} = React;
const {
  useDispatch
} = ReactRedux;
const {
  setCompetitionMode,
  clearCompetitionMode
} = svs.components.marketplaceCompetition.services.actions.competitionActions;

const useCompetitionDispatcher = (competitionId, productIds) => {
  const dispatch = useDispatch();
  const competition = useCompetition(competitionId);
  const isCorrectCompetitionProduct = useMemo(() => {
    var _competition$details;
    return (competition === null || competition === void 0 || (_competition$details = competition.details) === null || _competition$details === void 0 ? void 0 : _competition$details.productId) && productIds.includes(competition.details.productId);
  }, [competition, productIds]);
  const hasCompetitions = useMemo(() => isCorrectCompetitionProduct, [isCorrectCompetitionProduct]);
  const dispatchCompetition = useCallback(() => {
    if (isCorrectCompetitionProduct) {
      dispatch(setCompetitionMode({
        competitionId
      }));
    }
  }, [competitionId, dispatch, isCorrectCompetitionProduct]);
  const dispatchCompetitionClear = useCallback(() => {
    dispatch(clearCompetitionMode());
  }, [dispatch]);
  return useMemo(() => [hasCompetitions, dispatchCompetition, dispatchCompetitionClear], [dispatchCompetition, dispatchCompetitionClear, hasCompetitions]);
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.useCompetitionDispatcher', useCompetitionDispatcher);

 })(window);