(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/reducers/competition-result-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/reducers/competition-result-reducer.js');
"use strict";

const {
  combineReducers
} = RTK;
const {
  personalResultReducer,
  highscoreResultReducer
} = svs.components.marketplaceCompetition.services.reducers;
const competitionResultReducer = combineReducers({
  personalResult: personalResultReducer,
  highscore: highscoreResultReducer
});
setGlobal('svs.components.marketplaceCompetition.services.reducers.competitionResultReducer', competitionResultReducer);

 })(window);