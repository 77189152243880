(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-competition-branding.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-competition-branding.js');
"use strict";


const useCompetitionBranding = function () {
  let compType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  switch (compType.toLowerCase()) {
    case 'tipssm':
      return 'tips-sm';
    case 'tipsem':
      return 'europamastaren';
    default:
      return '';
  }
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.useCompetitionBranding', useCompetitionBranding);

 })(window);