(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/services/fetch-personal-results.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/services/fetch-personal-results.js');

const { jupiter } = svs.isServer ? require('trinidad-core').core : svs.core;

const getPath = (competitionId, teamIds, shouldFetchIndividualResults) => {
  const resultsPaths = [];
  if (shouldFetchIndividualResults) {
    resultsPaths.push(`/player/1/competitions/${competitionId}/result`);
  }
  if (teamIds) {
    teamIds.forEach((teamId) => {
      resultsPaths.push(`/player/1/competitions/${competitionId}/team/result?teamId=${teamId}`);
    });
  }
  return resultsPaths.length === 1 ?
resultsPaths.pop() :
'/multifetch?serial=true&urls=' + resultsPaths.map((path) => {
  return encodeURIComponent(path);
}).join('|');
};

const fetchPersonalResults = async (competitionId, teamIds, shouldFetchIndividualResults, req) => {
  if (!competitionId) {
    return {
      responses: [undefined, undefined]
    };
  }
  const options = {
    method: 'GET',
    path: getPath(competitionId, teamIds, shouldFetchIndividualResults),
    req
  };

  return jupiter.callAsync(options);
};

if (svs.isServer) {
  module.exports = fetchPersonalResults;
} else {
  setGlobal('svs.components.marketplaceCompetition.services.services.fetchPersonalResults', fetchPersonalResults);
}


 })(window);