(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/reducers/reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/reducers/reducer.js');
"use strict";


const {
  combineReducers
} = RTK;
const {
  makePlayerCompetitionReducer,
  makeCompetitionReducer,
  dialogReducer,
  competitionResultReducer,
  teamsReducer,
  createTeamReducer,
  wagersReducer
} = svs.components.marketplaceCompetition.services.reducers;
const {
  FetchState
} = svs.components.marketplaceCompetition.constants;
const normalizedCompetitionReducerState = {
  knownCompetitions: {},
  activeCompetitionId: undefined,
  participants: {}
};
const playerCompetitionInitialState = {
  signUps: {
    competitions: {},
    status: FetchState.Idle,
    lastFetch: null
  },
  draws: {}
};
const defaultArray = [];
const reducer = (preloadedCompetition, preloadedPlayerCompetitionDetails) => {
  if (preloadedCompetition) {
    normalizedCompetitionReducerState.knownCompetitions[preloadedCompetition.getCompetitionId()] = {
      data: preloadedCompetition.getSnapshot(),
      status: FetchState.Fulfilled,
      lastFetch: new Date().toISOString()
    };
  }
  const playerCompetitions = preloadedPlayerCompetitionDetails && Object.values(preloadedPlayerCompetitionDetails.playerCompetitions) || defaultArray;
  const playerCompetitionDraws = preloadedPlayerCompetitionDetails && Object.values(preloadedPlayerCompetitionDetails.playerCompetitionDraws) || defaultArray;
  if (playerCompetitions.length) {
    playerCompetitionInitialState.signUps.competitions = preloadedPlayerCompetitionDetails.playerCompetitions;
    playerCompetitionInitialState.signUps.status = FetchState.Fulfilled;
    playerCompetitionInitialState.signUps.lastFetch = new Date().toISOString();
  }
  if (playerCompetitionDraws.length) {
    const competitionIds = Object.keys(preloadedPlayerCompetitionDetails.playerCompetitionDraws);
    playerCompetitionDraws.forEach((compDraws, index) => {
      playerCompetitionInitialState.draws[competitionIds[index]] = {
        data: {
          draws: compDraws
        },
        status: FetchState.Fulfilled,
        lastFetch: new Date().toISOString()
      };
    });
  }
  return combineReducers({
    Competitions: makeCompetitionReducer(normalizedCompetitionReducerState),
    PlayerCompetitions: makePlayerCompetitionReducer(playerCompetitionInitialState),
    Teams: teamsReducer,
    Dialog: dialogReducer,
    CompetitionResult: competitionResultReducer,
    CreateTeam: createTeamReducer,
    Wagers: wagersReducer
  });
};
setGlobal('svs.components.marketplaceCompetition.services.reducers.reducer', reducer);

 })(window);