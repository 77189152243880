(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/reducers/create-team-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/reducers/create-team-reducer.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  createReducer
} = RTK;
const {
  createTeam,
  setCreateTeam
} = svs.components.marketplaceCompetition.services.actions.createTeamActions;
const {
  putParticipation
} = svs.components.marketplaceCompetition.services.actions.playerCompetitionActions;
const {
  FetchState
} = svs.components.marketplaceCompetition.constants;
const {
  processErrorCode
} = svs.components.marketplaceShared.competitionErrorCodes;
const teamsReducer = createReducer({}, builder => {
  builder.addCase(createTeam.fulfilled, (state, action) => {
    var _action$meta;
    return _objectSpread(_objectSpread({}, state), {}, {
      compType: (_action$meta = action.meta) === null || _action$meta === void 0 || (_action$meta = _action$meta.arg) === null || _action$meta === void 0 ? void 0 : _action$meta.compType,
      teamId: action.payload.teamId,
      status: FetchState.Fulfilled,
      error: undefined
    });
  }).addCase(createTeam.pending, state => _objectSpread(_objectSpread({}, state), {}, {
    status: FetchState.Pending,
    error: undefined
  })).addCase(createTeam.rejected, (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    status: FetchState.Rejected,
    error: {
      errorObj: action === null || action === void 0 ? void 0 : action.error,
      resolvedMessage: processErrorCode(action === null || action === void 0 ? void 0 : action.error).errorMessage || 'Något gick fel'
    }
  })).addCase(setCreateTeam, (state, action) => _objectSpread(_objectSpread({}, state), action.payload)).addCase(putParticipation.rejected, (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    status: 'ERRORED',
    error: {
      errorObj: action === null || action === void 0 ? void 0 : action.error,
      resolvedMessage: processErrorCode(action === null || action === void 0 ? void 0 : action.error).errorMessage || 'Något gick fel'
    }
  }));
});
setGlobal('svs.components.marketplaceCompetition.services.reducers.createTeamReducer', teamsReducer);

 })(window);