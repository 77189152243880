(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/selectors/create-team-selectors.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/selectors/create-team-selectors.js');
"use strict";


const selectCreateTeam = state => {
  return state.Competition.CreateTeam || {};
};
setGlobal('svs.components.marketplaceCompetition.services.selectors.createTeamSelector', {
  selectCreateTeam
});

 })(window);