(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/helpers/normalize-competition-result.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/helpers/normalize-competition-result.js');
"use strict";


const sortTeamMembersByName = (a, b) => {
  const firstnameCompare = a.firstname.localeCompare(b.firstname);
  if (firstnameCompare === 0) {
    return a.lastname.localeCompare(b.lastname);
  }
  return firstnameCompare;
};
const normalizeCompetitionResultIndividualDraws = competitionResultDraws => competitionResultDraws.map(draw => ({
  drawNumber: draw.drawNumber,
  correctCount: draw.corrects,
  isContributing: draw.contributing,
  hasPlayed: draw.hasPlayed,
  positionChangeSinceLast: draw.positionChangeSinceLast
}));
const normalizeCompetitionResultTeamDetails = competitionResultDraws => {
  let totalCorrects = 0;
  const draws = [];
  competitionResultDraws.forEach(r => {
    if (r.contributing) {
      totalCorrects += r.corrects;
    }
    const draw = {
      drawNumber: r.round,
      correctCount: r.corrects,
      isContributing: r.contributing,
      positionChangeSinceLast: r.positionChangeSinceLast,
      contributions: r.contributions.map((c, i) => {
        const firstName = c.anonymous ? 'Anonym' : c.firstname;
        const lastName = c.anonymous ? '' : c.lastname;
        return {
          uid: window.btoa("".concat(i).replace(/\s+/g, '')),
          isAnonymous: c.anonymous,
          firstname: firstName,
          lastname: lastName,
          location: c.location,
          correctCount: c.corrects,
          isContributing: c.contributing,
          hasPlayed: c.hasPlayed
        };
      })
    };
    draw.contributions.sort(sortTeamMembersByName);
    draws.push(draw);
  });
  return [draws, totalCorrects];
};
const normalizeCompetitionResultsIndividual = competitionResult => [competitionResult.payload.competitionId, {
  uid: competitionResult.id,
  isAnonymous: competitionResult.payload.anonymous,
  firstname: competitionResult.payload.anonymous ? 'Anonym' : competitionResult.payload.firstname,
  lastname: competitionResult.payload.anonymous ? '' : competitionResult.payload.lastname,
  position: competitionResult.payload.position,
  location: competitionResult.payload.location,
  totalCorrectCount: competitionResult.payload.corrects,
  draws: normalizeCompetitionResultIndividualDraws(competitionResult.payload.details)
}];
const normalizeCompetitionResultsTeam = competitionResult => {
  const [draws, totalCorrects] = normalizeCompetitionResultTeamDetails(competitionResult.payload.details);
  return [competitionResult.payload.competitionId, {
    uid: competitionResult.id,
    name: competitionResult.payload.name,
    position: competitionResult.payload.position,
    totalCorrectCount: totalCorrects,
    draws
  }];
};
const CompetitionForm = {
  Individual: 'Individual',
  Team: 'Team'
};
const normalizeCompetitionResults = competitionResults => {
  const result = {};
  const getCompetitionModel = competitionId => {
    if (!result[competitionId]) {
      result[competitionId] = {
        Teams: {
          totalCount: undefined,
          result: {}
        },
        Individuals: {
          totalCount: undefined,
          result: {}
        }
      };
    }
    return result[competitionId];
  };
  if (competitionResults !== null && competitionResults !== void 0 && competitionResults.result) {
    competitionResults.result.forEach(r => {
      if (r.payload.competitionForm === CompetitionForm.Individual) {
        const [competitionId, parsedR] = normalizeCompetitionResultsIndividual(r);
        const competitionModel = getCompetitionModel(competitionId);
        competitionModel.Individuals.result[parsedR.uid] = parsedR;
        competitionModel.Individuals.totalCount = competitionResults.totalCount;
      } else {
        const [competitionId, parsedR] = normalizeCompetitionResultsTeam(r);
        const competitionModel = getCompetitionModel(competitionId);
        competitionModel.Teams.result[parsedR.uid] = parsedR;
        competitionModel.Teams.totalCount = competitionResults.totalCount;
      }
    });
  }
  return result;
};
if (svs.isServer) {
  module.exports = normalizeCompetitionResults;
} else {
  setGlobal('svs.components.marketplaceCompetition.services.helpers.normalizeCompetitionResults', normalizeCompetitionResults);
}

 })(window);