(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/actions/player-competition-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/actions/player-competition-actions.js');
"use strict";


const {
  createAsyncThunk,
  createAction
} = RTK;
const {
  FetchState
} = svs.components.marketplaceCompetition.constants;
const {
  selectPlayerCompetition,
  selectPlayerCompetitionsStatus
} = svs.components.marketplaceCompetition.services.selectors.playerCompetitionSelectors;
const {
  userSession
} = svs.core;
const {
  competitionHandler
} = svs.components.marketplaceCompetition.competitionHandler;
const {
  services
} = svs.components.marketplaceCompetition.services;
const COMPETITION_CACHE_TTL_IN_MINUTES = 5;
const getElapsedMinutes = date => {
  const _date = new Date(date);
  if (!dateFns.isValid(_date)) {
    return Number.POSITIVE_INFINITY;
  }
  return dateFns.differenceInMinutes(new Date(), _date);
};
const fetchPlayerCompetitionCondition = (arg, _ref) => {
  let {
    getState
  } = _ref;
  if (userSession.isAnonymous()) {
    return false;
  }
  const state = getState();
  const {
    competitionId
  } = arg || {};
  const playerCompetition = competitionId ? selectPlayerCompetition(state, competitionId) : null;
  const [status, lastFetched] = selectPlayerCompetitionsStatus(state);
  const minutesSinceLastFetched = getElapsedMinutes(lastFetched);
  const isLoading = status === FetchState.Pending;
  const isRecentlyFetched = minutesSinceLastFetched < COMPETITION_CACHE_TTL_IN_MINUTES;
  if (isLoading) {
    return false;
  }
  if (isRecentlyFetched && (!competitionId || playerCompetition)) {
    return false;
  }
};
const fetchPlayerCompetitions = createAsyncThunk('fetchPlayerCompetitions', async options => {
  const {
    isActiveOnly = true
  } = options || {};
  const [result] = await competitionHandler().fetchPlayerCompetitions(isActiveOnly);
  return result;
}, {
  condition: fetchPlayerCompetitionCondition
});
const fetchPlayerCompetitionDrawStatusCondition = (competitionId, _ref2) => {
  var _state$Competition$Pl, _state$Competition$Pl2;
  let {
    getState
  } = _ref2;
  const hasPlayer = userSession.hasRole(userSession.roles.PLAYER);
  if (!hasPlayer) {
    return false;
  }
  const state = getState();
  const lastFetched = new Date((_state$Competition$Pl = state.Competition.PlayerCompetitions.draws[competitionId]) === null || _state$Competition$Pl === void 0 ? void 0 : _state$Competition$Pl.lastFetch);
  const status = (_state$Competition$Pl2 = state.Competition.PlayerCompetitions.draws[competitionId]) === null || _state$Competition$Pl2 === void 0 ? void 0 : _state$Competition$Pl2.status;
  const minutesSinceLastFetched = getElapsedMinutes(lastFetched);
  const isRecentlyFetched = minutesSinceLastFetched < COMPETITION_CACHE_TTL_IN_MINUTES;
  const isLoading = status !== null && status !== void 0 ? status : FetchState.Idle === FetchState.Pending;
  if (isLoading || isRecentlyFetched) {
    return false;
  }
};
const fetchPlayerCompetitionDrawStatus = createAsyncThunk('fetchPlayerCompetitionDrawStatus', competitionId => competitionHandler().fetchPlayerCompetitionDraws([competitionId]), {
  condition: fetchPlayerCompetitionDrawStatusCondition
});
const setPlayerCompetitionDraw = createAction('setPlayerCompetitionDraw', _ref3 => {
  let {
    drawNumber,
    competitionId
  } = _ref3;
  return {
    payload: {
      drawNumber,
      competitionId
    }
  };
});
const deletePlayerCompetitionDraw = createAction('deletePlayerCompetitionDraw', _ref4 => {
  let {
    drawNumber,
    competitionId
  } = _ref4;
  return {
    payload: {
      drawNumber,
      competitionId
    }
  };
});
const setPlayerCompetitionSignedUp = createAction('setPlayerCompetitionSignedUp', _ref5 => {
  let {
    competitionId
  } = _ref5;
  return {
    payload: {
      competitionId
    }
  };
});
const putParticipation = createAsyncThunk('putParticipation', _ref6 => {
  let {
    competitionId
  } = _ref6;
  return services.putParticipation(competitionId);
});
setGlobal('svs.components.marketplaceCompetition.services.actions.playerCompetitionActions', {
  deletePlayerCompetitionDraw,
  fetchPlayerCompetitions,
  fetchPlayerCompetitionDrawStatus,
  setPlayerCompetitionDraw,
  setPlayerCompetitionSignedUp,
  putParticipation
});

 })(window);