(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/middlewares/make-competition-mw.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/middlewares/make-competition-mw.js');
"use strict";


const {
  setPlayerCompetitionDraw,
  deletePlayerCompetitionDraw
} = svs.components.marketplaceCompetition.services.actions.playerCompetitionActions;
const {
  fetchCompetition
} = svs.components.marketplaceCompetition.services.actions.competitionActions;
const {
  isAnyOf
} = RTK;
const {
  onEvent,
  eventNames
} = svs.components.lbUtils.onEvents;


const makeCompetitionMw = _ref => {
  let {
    cancelBetFulfilledAction,
    getPlayerWagerSelector
  } = _ref;
  return _ref2 => {
    let {
      dispatch,
      getState
    } = _ref2;
    onEvent(eventNames.PAYMENT_SUCCESS, _ref3 => {
      var _payable$isCompetitio;
      let {
        detail: {
          payable
        }
      } = _ref3;
      if ((_payable$isCompetitio = payable.isCompetition) !== null && _payable$isCompetitio !== void 0 && _payable$isCompetitio.call(payable)) {
        const bet = payable.getCompetitionBet();
        const competitionId = payable.competitionId[0];
        dispatch(setPlayerCompetitionDraw({
          drawNumber: bet.drawNumber,
          competitionId
        }));
      }
    });
    return next => action => {
      const isCancelledBetAction = isAnyOf(cancelBetFulfilledAction);
      if (isCancelledBetAction(action)) {
        var _action$meta, _action$meta2, _wager;
        const state = getState();
        const wagerId = (action === null || action === void 0 || (_action$meta = action.meta) === null || _action$meta === void 0 || (_action$meta = _action$meta.extraWagerData) === null || _action$meta === void 0 ? void 0 : _action$meta.wagerId) || (action === null || action === void 0 || (_action$meta2 = action.meta) === null || _action$meta2 === void 0 || (_action$meta2 = _action$meta2.arg) === null || _action$meta2 === void 0 || (_action$meta2 = _action$meta2.originalArgs) === null || _action$meta2 === void 0 ? void 0 : _action$meta2.wagerId);
        let wager;
        if (wagerId) {
          wager = getPlayerWagerSelector.select({
            wagerId
          })(state);
        }
        const isCompetitonWager = (_wager = wager) === null || _wager === void 0 || (_wager = _wager.data) === null || _wager === void 0 || (_wager = _wager.productBet) === null || _wager === void 0 ? void 0 : _wager.isCompetitionWager;
        if (isCompetitonWager) {
          var _wager2;
          const drawNumber = (_wager2 = wager) === null || _wager2 === void 0 || (_wager2 = _wager2.data) === null || _wager2 === void 0 || (_wager2 = _wager2.productBet) === null || _wager2 === void 0 ? void 0 : _wager2.drawNumber;
          const playerCompetitionsCompetitionIds = Object.keys(state.Competition.PlayerCompetitions.draws);
          const competitionIdWithDrawNumber = playerCompetitionsCompetitionIds.find(competitionId => {
            var _state$Competition$Pl;
            return (_state$Competition$Pl = state.Competition.PlayerCompetitions.draws[competitionId]) === null || _state$Competition$Pl === void 0 || (_state$Competition$Pl = _state$Competition$Pl.data) === null || _state$Competition$Pl === void 0 || (_state$Competition$Pl = _state$Competition$Pl.draws) === null || _state$Competition$Pl === void 0 ? void 0 : _state$Competition$Pl.includes(drawNumber);
          });
          if (competitionIdWithDrawNumber) {
            dispatch(deletePlayerCompetitionDraw({
              drawNumber,
              competitionId: competitionIdWithDrawNumber
            }));
          }
        }
      }
      next(action);
    };
  };
};
setGlobal('svs.components.marketplaceCompetition.services.middlewares.makeCompetitionMw', makeCompetitionMw);

 })(window);