(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-personal-results-team.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-personal-results-team.js');
"use strict";

const {
  useEffect
} = React;
const {
  FetchState
} = svs.components.marketplaceCompetition.constants;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  fetchPersonalResultsAction
} = svs.components.marketplaceCompetition.services.actions.personalResultActions;
const {
  selectPersonalResults
} = svs.components.marketplaceCompetition.services.selectors.personalResultSelectors;
const usePersonalResultsTeam = (competitionId, teamId) => {
  var _competitionResult$te;
  const dispatch = useDispatch();
  const competitionResult = useSelector(state => selectPersonalResults(state, competitionId));
  const teamResult = (_competitionResult$te = competitionResult.teams[teamId]) !== null && _competitionResult$te !== void 0 ? _competitionResult$te : undefined;
  useEffect(() => {
    if (!teamResult && (teamResult === null || teamResult === void 0 ? void 0 : teamResult.status) !== FetchState.Pending) {
      const teamIds = [teamId];
      const shouldFetchIndividualResults = false;
      dispatch(fetchPersonalResultsAction({
        competitionId,
        teamIds,
        shouldFetchIndividualResults
      }));
    }
  }, [teamResult, competitionId, teamId, dispatch]);
  return teamResult;
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.usePersonalResultsTeam', usePersonalResultsTeam);

 })(window);