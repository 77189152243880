(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/selectors/personal-result-selectors.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/selectors/personal-result-selectors.js');
"use strict";

const selectPersonalResults = (state, competitionId) => state.Competition.CompetitionResult.personalResult.Competitions[competitionId];
const selectIndividualResults = (state, competitionId) => selectPersonalResults(state, competitionId).individual;
const selectTeamResults = (state, competitionId, teamId) => selectPersonalResults(state, competitionId).teams[teamId];
setGlobal('svs.components.marketplaceCompetition.services.selectors.personalResultSelectors', {
  selectPersonalResults,
  selectIndividualResults,
  selectTeamResults
});

 })(window);