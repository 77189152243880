(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/selectors/highscore-result-selectors.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/selectors/highscore-result-selectors.js');
"use strict";


const {
  HighscoreListType
} = svs.components.marketplaceCompetition.services.constants.highscoreConstants;
const {
  FetchState
} = svs.components.marketplaceCompetition.constants;
const selectCompetitionTeamResult = (state, competitionId) => {
  var _state$Competition$Co, _state$Competition$Co2;
  return Object.values((_state$Competition$Co = (_state$Competition$Co2 = state.Competition.CompetitionResult.highscore.Teams.Competitions[competitionId]) === null || _state$Competition$Co2 === void 0 || (_state$Competition$Co2 = _state$Competition$Co2.data) === null || _state$Competition$Co2 === void 0 ? void 0 : _state$Competition$Co2.result) !== null && _state$Competition$Co !== void 0 ? _state$Competition$Co : {});
};
const selectCompetitionTeamResultCount = (state, competitionId) => {
  var _state$Competition$Co3, _state$Competition$Co4;
  return (_state$Competition$Co3 = (_state$Competition$Co4 = state.Competition.CompetitionResult.highscore.Teams.Competitions[competitionId]) === null || _state$Competition$Co4 === void 0 || (_state$Competition$Co4 = _state$Competition$Co4.data) === null || _state$Competition$Co4 === void 0 ? void 0 : _state$Competition$Co4.totalCount) !== null && _state$Competition$Co3 !== void 0 ? _state$Competition$Co3 : null;
};
const selectCompetitionTeamResultTotalCount = (state, competitionId) => {
  var _state$Competition$Co5, _state$Competition$Co6;
  return (_state$Competition$Co5 = (_state$Competition$Co6 = state.Competition.Competitions.participants[competitionId]) === null || _state$Competition$Co6 === void 0 || (_state$Competition$Co6 = _state$Competition$Co6.data) === null || _state$Competition$Co6 === void 0 ? void 0 : _state$Competition$Co6.numberOfTeams) !== null && _state$Competition$Co5 !== void 0 ? _state$Competition$Co5 : null;
};
const selectCompetitionTeamResultPending = (state, competitionId) => {
  var _state$Competition$Co7, _state$Competition$Co8;
  return ((_state$Competition$Co7 = (_state$Competition$Co8 = state.Competition.CompetitionResult.highscore.Teams.Competitions[competitionId]) === null || _state$Competition$Co8 === void 0 ? void 0 : _state$Competition$Co8.status) !== null && _state$Competition$Co7 !== void 0 ? _state$Competition$Co7 : FetchState.Idle) === FetchState.Pending;
};
const selectCompetitionTeamResultSearchTerm = state => state.Competition.CompetitionResult.highscore.Teams.SearchTerm;
const selectCompetitionTeamResultComplete = (state, competitionId) => {
  const totalCount = selectCompetitionTeamResultCount(state, competitionId);
  const loadedCount = selectCompetitionTeamResult(state, competitionId).length;
  return totalCount === loadedCount;
};
const selectCompetitionIndividualResult = (state, competitionId) => {
  var _state$Competition$Co9, _state$Competition$Co10;
  return Object.values((_state$Competition$Co9 = (_state$Competition$Co10 = state.Competition.CompetitionResult.highscore.Individuals.Competitions[competitionId]) === null || _state$Competition$Co10 === void 0 || (_state$Competition$Co10 = _state$Competition$Co10.data) === null || _state$Competition$Co10 === void 0 ? void 0 : _state$Competition$Co10.result) !== null && _state$Competition$Co9 !== void 0 ? _state$Competition$Co9 : {});
};
const selectCompetitionIndividualResultCount = (state, competitionId) => {
  var _state$Competition$Co11, _state$Competition$Co12;
  return (_state$Competition$Co11 = (_state$Competition$Co12 = state.Competition.CompetitionResult.highscore.Individuals.Competitions[competitionId]) === null || _state$Competition$Co12 === void 0 || (_state$Competition$Co12 = _state$Competition$Co12.data) === null || _state$Competition$Co12 === void 0 ? void 0 : _state$Competition$Co12.totalCount) !== null && _state$Competition$Co11 !== void 0 ? _state$Competition$Co11 : null;
};
const selectCompetitionIndividualResultTotalCount = (state, competitionId) => {
  var _state$Competition$Co13, _state$Competition$Co14;
  return (_state$Competition$Co13 = (_state$Competition$Co14 = state.Competition.Competitions.participants[competitionId]) === null || _state$Competition$Co14 === void 0 || (_state$Competition$Co14 = _state$Competition$Co14.data) === null || _state$Competition$Co14 === void 0 ? void 0 : _state$Competition$Co14.numberOfPlayers) !== null && _state$Competition$Co13 !== void 0 ? _state$Competition$Co13 : null;
};
const selectCompetitionIndividualResultPending = (state, competitionId) => {
  var _state$Competition$Co15, _state$Competition$Co16;
  return ((_state$Competition$Co15 = (_state$Competition$Co16 = state.Competition.CompetitionResult.highscore.Individuals.Competitions[competitionId]) === null || _state$Competition$Co16 === void 0 ? void 0 : _state$Competition$Co16.status) !== null && _state$Competition$Co15 !== void 0 ? _state$Competition$Co15 : FetchState.Idle) === FetchState.Pending;
};
const selectCompetitionIndividualResultSearchTerm = state => state.Competition.CompetitionResult.highscore.Individuals.SearchTerm;
const selectCompetitionIndividualResultComplete = (state, competitionId) => {
  const totalCount = selectCompetitionIndividualResultCount(state, competitionId);
  const loadedCount = selectCompetitionIndividualResult(state, competitionId).length;
  return totalCount === loadedCount;
};
const selectCompetitionResult = (state, competitionId, type) => {
  if (type === HighscoreListType.Team) {
    return selectCompetitionTeamResult(state, competitionId);
  }
  return selectCompetitionIndividualResult(state, competitionId);
};
const selectCompetitionResultCount = (state, competitionId, type) => {
  if (type === HighscoreListType.Team) {
    return selectCompetitionTeamResultTotalCount(state, competitionId);
  }
  return selectCompetitionIndividualResultTotalCount(state, competitionId);
};
const selectCompetitionResultPending = (state, competitionId, type) => {
  if (type === HighscoreListType.Team) {
    return selectCompetitionTeamResultPending(state, competitionId);
  }
  return selectCompetitionIndividualResultPending(state, competitionId);
};
const selectCompetitionResultSearchTerm = (state, type) => {
  if (type === HighscoreListType.Team) {
    return selectCompetitionTeamResultSearchTerm(state);
  }
  return selectCompetitionIndividualResultSearchTerm(state);
};
const selectCompetitionResultComplete = (state, competitionId, type) => {
  if (type === HighscoreListType.Team) {
    return selectCompetitionTeamResultComplete(state, competitionId);
  }
  return selectCompetitionIndividualResultComplete(state, competitionId);
};
setGlobal('svs.components.marketplaceCompetition.services.selectors.competitionResultSelectors', {
  selectCompetitionResult,
  selectCompetitionResultCount,
  selectCompetitionResultPending,
  selectCompetitionResultSearchTerm,
  selectCompetitionResultComplete
});

 })(window);