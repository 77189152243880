(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-player-competition-draws.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-player-competition-draws.js');
"use strict";


const {
  useEffect
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  fetchPlayerCompetitionDrawStatus
} = svs.components.marketplaceCompetition.services.actions.playerCompetitionActions;
const {
  selectPlayerCompetitionDrawsStatus
} = svs.components.marketplaceCompetition.services.selectors.playerCompetitionSelectors;

const usePlayerCompetitionDraws = function (competitionId) {
  let allowDispatch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const dispatch = useDispatch();
  const competitionDrawStatus = useSelector(state => selectPlayerCompetitionDrawsStatus(state, competitionId));
  const hasNoDrawsAndShouldFetchNew = allowDispatch && competitionId;
  useEffect(() => {
    if (hasNoDrawsAndShouldFetchNew) {
      dispatch(fetchPlayerCompetitionDrawStatus(competitionId));
    }
  }, [competitionId, dispatch, hasNoDrawsAndShouldFetchNew]);
  return competitionDrawStatus;
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.usePlayerCompetitionDraws', usePlayerCompetitionDraws);

 })(window);