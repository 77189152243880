(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/actions/dialog-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/actions/dialog-actions.js');
"use strict";


const {
  createAction
} = RTK;
const showDialogAction = createAction('show_dialog');
const closeDialogAction = createAction('close_dialog');
setGlobal('svs.components.marketplaceCompetition.services.actions.dialogActions', {
  showDialogAction,
  closeDialogAction
});

 })(window);