(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/actions/teams-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/actions/teams-actions.js');
"use strict";


const {
  createAsyncThunk
} = RTK;
const {
  jupiter
} = svs.core;
const {
  FetchState
} = svs.components.marketplaceCompetition.constants;
const {
  normalizePersonalResults
} = svs.components.marketplaceCompetition.services.helpers;
const {
  selectTeams,
  selectTeamResult
} = svs.components.marketplaceCompetition.services.selectors;
const {
  isAnonymous
} = svs.core.session.data.userSession;
const {
  competitionHandler
} = svs.components.marketplaceCompetition.competitionHandler;
const fetchTeamsCondition = (competitionId, _ref) => {
  let {
    getState
  } = _ref;
  if (!competitionId) {
    return false;
  }
  if (isAnonymous) {
    return false;
  }
  const state = getState();
  const {
    status,
    lastFetched
  } = selectTeams(state, competitionId);
  if (status === FetchState.Pending || new Date(lastFetched).getTime() + 5000 > Date.now()) {
    return false;
  }
};
const fetchTeams = createAsyncThunk('fetchTeams', competitionId => competitionHandler().fetchPlayerCompetitionTeams(competitionId), {
  condition: fetchTeamsCondition
});
const leaveTeam = createAsyncThunk('leaveTeam', async _ref2 => {
  let {
    competitionId,
    teamId,
    customerId
  } = _ref2;
  const {
    response
  } = await jupiter.callAsync({
    method: 'DELETE',
    path: "/competition/2/competitions/".concat(competitionId, "/teamInvitations?&teamId=").concat(teamId, "&customerId=").concat(customerId)
  });
  return response;
});
const deleteTeam = createAsyncThunk('deleteTeam', async _ref3 => {
  let {
    competitionId,
    teamId
  } = _ref3;
  const {
    response
  } = await jupiter.callAsync({
    method: 'DELETE',
    path: "/competition/1/competitions/".concat(competitionId, "/teams?teamId=").concat(teamId)
  });
  return response;
});
const fetchTeamResult = createAsyncThunk('competition/fetchTeamResults', async _ref4 => {
  let {
    competitionId,
    teamId
  } = _ref4;
  const response = await competitionHandler().fetchPlayerCompetitionResults(competitionId, [teamId]);
  const [individualResult, teamsResult] = normalizePersonalResults(response);
  return [individualResult, teamsResult];
}, {
  condition: (_ref5, _ref6) => {
    let {
      competitionId,
      teamId
    } = _ref5;
    let {
      getState
    } = _ref6;
    if (!competitionId) {
      return false;
    }
    if (isAnonymous) {
      return false;
    }
    if (!teamId) {
      return false;
    }
    const state = getState();
    const {
      status,
      lastFetch
    } = selectTeamResult(state, competitionId, teamId);
    if (status === FetchState.Pending || new Date(lastFetch).getTime() + 5000 > Date.now()) {
      return false;
    }
  }
});
const joinTeamByInvitation = createAsyncThunk('invitation/join', async (_ref7, _ref8) => {
  let {
    competitionId,
    teamId,
    invitationId
  } = _ref7;
  let {
    rejectWithValue
  } = _ref8;
  try {
    const response = await svs.components.marketplaceShared.invitation.join(competitionId, teamId, invitationId);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});
setGlobal('svs.components.marketplaceCompetition.services.actions.teamsActions', {
  fetchTeams,
  fetchTeamResult,
  leaveTeam,
  deleteTeam,
  joinTeamByInvitation
});

 })(window);