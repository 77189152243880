(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-active-competition-id.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-active-competition-id.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  selectActiveCompetitionId
} = svs.components.marketplaceCompetition.services.selectors.competitionSelectors;
const useActiveCompetitionId = () => useSelector(selectActiveCompetitionId);
setGlobal('svs.components.marketplaceCompetition.services.hooks.useActiveCompetitionId', useActiveCompetitionId);

 })(window);