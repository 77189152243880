(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/actions/highscore-result-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/actions/highscore-result-actions.js');
"use strict";

const {
  createAsyncThunk
} = RTK;
const {
  jupiter
} = svs.core;
const {
  normalizeCompetitionResults
} = svs.components.marketplaceCompetition.services.helpers;
const {
  HighscoreListType
} = svs.components.marketplaceCompetition.services.constants.highscoreConstants;
const {
  CompetitionType
} = svs.components.marketplaceCompetition.constants;
const parseCsv = children => {
  const childSplit = ',';
  const pairSplit = ';';
  const csvChildren = children.filter(_ref => {
    let [key] = _ref;
    return Boolean(key);
  }).map(keyvalue => keyvalue.join(pairSplit));
  return csvChildren.join(childSplit);
};
const fetchCompetitionHighscore = createAsyncThunk('competition/fetchCompetitionHighscore', _ref2 => {
  let {
    competitionId,
    payloadType,
    count,
    offset,
    location,
    search,
    type = CompetitionType.TipsSM
  } = _ref2;
  return new Promise((resolve, reject) => {
    const query = new URLSearchParams();
    query.set('ctx', 'competition');
    query.set('type', type.toLowerCase()); 
    query.set('andfilter', parseCsv([['payload.competitionForm', payloadType === HighscoreListType.Team ? 'Team' : 'Individual'], ['payload.competitionId', competitionId], location && location.length > 0 ? ['payload.location', location] : []]));
    query.set('count', count);
    query.set('offset', offset);
    query.set('operator', 'and');
    query.set('sort', parseCsv([['payload.position', 'asc']]));
    if (search && search.length > 0) {
      query.set('q', search);
    }
    jupiter.get("/search/1/query?".concat(query.toString()), response => {
      resolve(normalizeCompetitionResults(response));
    }, error => {
      reject(error);
    });
  });
});
setGlobal('svs.components.marketplaceCompetition.services.actions.highscoreResultActions', {
  fetchCompetitionHighscore
});

 })(window);