(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-teams.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-teams.js');
"use strict";


const {
  useEffect,
  useMemo
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  fetchTeams
} = svs.components.marketplaceCompetition.services.actions.teamsActions;
const {
  selectTeams
} = svs.components.marketplaceCompetition.services.selectors;
const {
  usePlayerCompetitions
} = svs.components.marketplaceCompetition.services.hooks;
const {
  FetchState
} = svs.components.marketplaceCompetition.constants;
const useTeams = competitionId => {
  const dispatch = useDispatch();
  const playerCompetitions = usePlayerCompetitions();
  const playerCompetitionIds = Object.keys(playerCompetitions);
  const competitionIds = playerCompetitionIds.map(compId => parseInt(compId, 10));
  const isParticipating = playerCompetitions && competitionIds.find(id => id === competitionId);
  const {
    data: teams,
    status
  } = useSelector(state => selectTeams(state, competitionId));
  const currentCompetition = isParticipating ? playerCompetitions[isParticipating] : null;
  const teamsData = {
    teams,
    status: status || FetchState.Pending
  };
  const memoizedTeams = useMemo(() => teamsData
  , [teams, status]);
  useEffect(() => {
    if (isParticipating && currentCompetition && !currentCompetition.isDirty) {
      dispatch(fetchTeams(competitionId));
    }
  }, [competitionId, currentCompetition, dispatch, isParticipating]);
  return memoizedTeams;
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.useTeams', useTeams);

 })(window);