(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/helpers/normalize-personal-result.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/helpers/normalize-personal-result.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  log
} = svs.core;
const logger = log.getLogger('marketplace-competition:normalize-personal-results');

const normalizeIndividualDrawResult = (drawResult, previousDraw) => {
  const normalizedDraw = {
    drawNumber: undefined,
    hasHighestScore: false,
    isContributing: false,
    hasPlayed: false,
    position: undefined,
    winAmount: '',
    correctCount: 0,
    unhedged: 0,
    positionChangeSinceLast: 0
  };
  if (drawResult) {
    normalizedDraw.drawNumber = drawResult.drawnum;
    normalizedDraw.isContributing = drawResult.contributing;
    normalizedDraw.hasPlayed = drawResult.hasPlayed;
    normalizedDraw.position = drawResult.position;
    normalizedDraw.winAmount = drawResult.winAmount;
    normalizedDraw.correctCount = drawResult.corrects;
    normalizedDraw.unhedged = drawResult.unhedged;
    normalizedDraw.positionChangeSinceLast = previousDraw ? previousDraw.position - normalizedDraw.position : 0;
  }
  return normalizedDraw;
};
const sortTeamMembersByName = (a, b) => {
  const firstnameCompare = a.firstname.localeCompare(b.firstname);
  if (firstnameCompare === 0) {
    return a.lastname.localeCompare(b.lastname);
  }
  return firstnameCompare;
};
const normalizeTeamContributors = (contributions, highestScore) => {
  const normalizedContributions = contributions.map((c, i) => {
    const name = c.name ? c.name.split(' ', 2) : ['', ''];
    return {
      uid: window.btoa("".concat(i)).replace(/\s+/i, ''),
      hasHighestScore: c.corrects === highestScore,
      isAnonymous: c.anonymous,
      isContributing: c.contributing,
      correctCount: c.corrects,
      hasPlayed: c.hasPlayed,
      unhedged: c.unhedged,
      firstname: name[0].trim(),
      lastname: name[1] ? name[1].trim() : ''
    };
  });
  normalizedContributions.sort(sortTeamMembersByName);
  return normalizedContributions;
};
const normalizeTeamDrawResult = (drawResult, previousDraw) => {
  const highestScore = drawResult.contributions.reduce((acc, prev) => acc > prev.corrects ? acc : prev.corrects, 0);
  return _objectSpread(_objectSpread({}, normalizeIndividualDrawResult(drawResult, previousDraw)), {}, {
    contributions: normalizeTeamContributors(drawResult.contributions, highestScore)
  });
};
const normalizeIndividualResult = function (individualResultResponse) {
  let normalizeDrawResult = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : normalizeIndividualDrawResult;
  const normalizedResult = {
    isAnonymous: false,
    isResultsFinal: false,
    winAmount: '',
    position: {
      best: undefined,
      current: undefined,
      bestRound: undefined
    },
    correctCount: undefined,
    unhedged: undefined,
    currentDraw: undefined,
    drawResults: []
  };
  if (individualResultResponse !== null && individualResultResponse !== void 0 && individualResultResponse.tipsCompResult) {
    normalizedResult.isAnonymous = individualResultResponse.tipsCompResult.anonymous;
    normalizedResult.isResultsFinal = individualResultResponse.tipsCompResult.resultIsFinal;
    normalizedResult.winAmount = individualResultResponse.tipsCompResult.winAmount;
    normalizedResult.position.best = individualResultResponse.tipsCompResult.bestPosition;
    normalizedResult.position.current = individualResultResponse.tipsCompResult.position;
    normalizedResult.position.bestRound = individualResultResponse.tipsCompResult.bestPositionRound;
    normalizedResult.correctCount = individualResultResponse.tipsCompResult.corrects;
    normalizedResult.unhedged = individualResultResponse.tipsCompResult.unhedged;
    normalizedResult.currentDraw = individualResultResponse.tipsCompResult.currentDraw;
    let previousDraw;
    const highestScore = individualResultResponse.tipsCompResult.drawResults.reduce((acc, prev) => acc > prev.corrects ? acc : prev.corrects, 0);
    normalizedResult.drawResults = individualResultResponse.tipsCompResult.drawResults.map(d => {
      const draw = normalizeDrawResult(d, previousDraw, highestScore);
      previousDraw = draw;
      return draw;
    });
  }
  return normalizedResult;
};
const normalizeTeamResult = teamResult => {
  var _teamResult$tipsCompR, _teamResult$tipsCompR2;
  return _objectSpread(_objectSpread({}, normalizeIndividualResult(teamResult, normalizeTeamDrawResult)), {}, {
    id: (_teamResult$tipsCompR = teamResult.tipsCompResult) === null || _teamResult$tipsCompR === void 0 ? void 0 : _teamResult$tipsCompR.id,
    teamName: (_teamResult$tipsCompR2 = teamResult.tipsCompResult) === null || _teamResult$tipsCompR2 === void 0 ? void 0 : _teamResult$tipsCompR2.teamName
  });
};
const normalizePersonalResults = response => {
  const responses = response.responses ? response.responses : [response];
  const teamResult = {};
  let individualResult;
  responses.forEach(response => {
    if (response.error) {
      logger(response.error);
      return;
    }
    if (!response.tipsCompResult) {
      return;
    }
    if (response.tipsCompResult.teamName) {
      const normalized = normalizeTeamResult(response);
      if (normalized.id) {
        teamResult[normalized.id] = normalized;
      }
    } else {
      individualResult = normalizeIndividualResult(response);
    }
  });
  return [individualResult, teamResult];
};
if (svs.isServer) {
  module.exports = {
    normalizePersonalResults
  };
} else {
  setGlobal('svs.components.marketplaceCompetition.services.helpers.normalizePersonalResults', normalizePersonalResults);
}

 })(window);