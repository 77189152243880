(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/hooks/use-player-competitions.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/hooks/use-player-competitions.js');
"use strict";


const {
  useEffect
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  fetchPlayerCompetitions
} = svs.components.marketplaceCompetition.services.actions.playerCompetitionActions;
const {
  selectPlayerCompetitions
} = svs.components.marketplaceCompetition.services.selectors.playerCompetitionSelectors;
const {
  userSession
} = svs.core;
const usePlayerCompetitions = options => {
  const {
    competitionId,
    forceFetch = false,
    isActiveOnly = true
  } = options || {};
  const dispatch = useDispatch();
  const playerCompetitions = useSelector(selectPlayerCompetitions);
  useEffect(() => {
    if (forceFetch || !userSession.isAnonymous()) {
      dispatch(fetchPlayerCompetitions({
        competitionId,
        isActiveOnly
      }));
    }
  }, [competitionId, dispatch, forceFetch, isActiveOnly]);
  return playerCompetitions;
};
setGlobal('svs.components.marketplaceCompetition.services.hooks.usePlayerCompetitions', usePlayerCompetitions);

 })(window);