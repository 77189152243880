(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/helpers/normalize-wagers.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/helpers/normalize-wagers.js');
"use strict";


const normalizeWagers = wagers => {
  if (Array.isArray(wagers) && wagers.length > 0) {
    const normalized = {};
    wagers.forEach(wager => {
      const drawNumber = parseInt(wager.currentDrawNumber, 10);
      normalized[drawNumber] = {
        wagerSerial: wager.wagerId
      };
    });
    return normalized;
  }
  return null;
};

if (svs.isServer) {
  module.exports = {
    normalizeWagers
  };
} else {
  setGlobal('svs.components.marketplaceCompetition.services.helpers.normalizeWagers', {
    normalizeWagers
  });
}

 })(window);